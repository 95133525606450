import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";

import './FormaParte.css';
import { useJoinUsPage } from "../../../context/AboutUs/JoinUs/JoinUs.context";
import { CycleStep, JoinUsVideo } from "../../../context/AboutUs/JoinUs/JoinUsPage.models";

export const FormaParte = () => {
    const context = useJoinUsPage();

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Nosotros",
            url: "/Nosotros"
        },
        {
            title: "Sé Parte de Nosotros",
            url: "/Nosotros/FormaParte"
        }
    ];

    return (
        <article id="forma-parte">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div id="intro" dangerouslySetInnerHTML={{ __html: context?.Intro?.Resumen || "" }}></div>

                <div className="form-link-container">
                    <a id="enlace-formulario" href={context?.Intro?.EnlaceFormulario} target="_blank" className="primary-button">
                        Sé Parte de Nosotros
                        <i className="icon-link"></i>
                    </a>
                </div>

                <ul className="video-container">
                    {
                        context?.Videos?.map((video: JoinUsVideo) => {
                            return (
                                <li className="video" key={video.titulo}>
                                    <iframe src={video.enlace} title={video.titulo}
                                        frameBorder={0}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                                </li>
                            );
                        })
                    }
                </ul>

                <div id="beneficios-container">
                    <h2>Nuestros Beneficios y Programas Especiales</h2>
                    <ul className="beneficios">
                        {
                            context?.Beneficios?.map((beneficio: string) => {
                                return (
                                    <li className="beneficio" key={beneficio}>{beneficio}</li>
                                );
                            })
                        }
                    </ul>
                </div>

                <div id="programa-pasantia">
                    <h2>Programa de Pasantías</h2>

                    <div className="intro" dangerouslySetInnerHTML={{ __html: context?.PasantiaIntro?.Resumen || "" }}></div>

                    <div className="steps-container">
                        <ul className="steps">
                            {
                                context?.CicloEjecucion?.map((step: CycleStep, index: number) => {
                                    return (
                                        <li key={step.titulo}>
                                            <div className="step">
                                                <div className="step-icon">
                                                    <img src={step.icon} alt={"Ícono " + step.titulo} />
                                                </div>
                                                <span className="name">{step.titulo}</span>
                                            </div>

                                            {
                                                context?.CicloEjecucion?.length > index + 1 &&
                                                <span className="icon-container">
                                                    <span className="icon-arrow-step-right">
                                                        <span className="icon- path1"></span>
                                                        <span className="icon- path2"></span>
                                                    </span>
                                                </span>
                                            }
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>

                    <div className="req-container">
                        <p>Pueden aplicar bachilleres y universitarios, siempre que sea requerido por la casa de estudios.
                        </p>

                        <div className="white-box">
                            <h3>Requisitos para pasantes:</h3>

                            <ol className="reqs">
                                {
                                    context?.RequisitosPasantia?.map((req: string) => {
                                        return (
                                            <li className="req" key={req}>{req}</li>
                                        );
                                    })
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </article >
    );
};