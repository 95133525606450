import "./documentsContainer.scoped.css";
import { Documento } from "../../context/Models/Document.models";
import { DocumentCard } from "../../components/Cards/DocumentCard/documentCard";
import { useEffect, useState } from "react";

export interface DocumentsContainerProps {
    filter?: boolean;
    documents?: Documento[];
}

export function DocumentsContainer(props: DocumentsContainerProps) {

    const [filteredDocuments, setFilteredDocuments] = useState(Array<Documento>());
    const [filterYears, setFilterYears] = useState(Array<string>());
    const [fileYear, setYearFilter] = useState("Todos");

    useEffect(() => {
        if (props.documents)
            setFilterYears(props.documents.map(document => document.Ano || ""));
    }, [props.documents]);

    useEffect(() => {
        if (props.documents) {
            if (fileYear == "Todos")
                setFilteredDocuments(props.documents);
            else
                setFilteredDocuments(props.documents.filter((document: Documento) => { return document.Ano == fileYear; }));
        }
    }, [props.documents, fileYear]);

    return (
        <>
            {
                props.filter &&
                <div className="filtro-documentos">
                    <div className="datepicker-container">
                        <div className="datepicker">
                            <select id="document-year"
                                onChange={(e) => setYearFilter(e.target.value)}
                                value={fileYear} >
                                <option value="Todos" selected>Todos</option>
                                {
                                    filterYears.map((year: string) => {
                                        return (
                                            <option value={year} key={year}>{year}</option>
                                        );
                                    })
                                }
                            </select>
                            <label htmlFor="document-year">
                                <span className="icon-calendar"></span> Año
                                <span className="pl-1 selected-year">{fileYear}</span>
                            </label>
                        </div>
                    </div>
                </div>
            }

            <div className="ano-group">
                <ul className="documentos">
                    {
                        filteredDocuments.map((document: Documento) => {
                            return (
                                <DocumentCard document={document} key={document.Nombre} />
                            );
                        })
                    }
                </ul>
            </div>
        </>
    );
}
