import axios from "axios";

const SharePointHeaders = {
    "Accept": "application/json;odata=verbose",
    "Content-Type": "application/json;odata=verbose",
};

const RequestConfig = {
    headers: SharePointHeaders
}

export async function Get(getUrl: string) {
    try {
        return await axios.get(getUrl, RequestConfig).then(response => {
            if (response.status == 200 && response.data && response.data.d && response.data.d.results)
                return response.data.d.results;
            else
                return [];
        });
    } catch (error) {
        console.error(error);
        return [];
    }
}
export async function GetTitles(getUrl: string) {
    return Get(getUrl).then(response => {
        let result: Array<string> = [];

        response.forEach((item: any) => {
            result.push(item.Title);
        });

        return result;
    });
}

export async function GetFieldOptions(listUrl: string, fieldName: string) {
    try {
        return await axios.get(`${listUrl}/fields?$filter=EntityPropertyName eq '${fieldName}'`, RequestConfig).then(response => {
            if (response.status == 200 && response.data && response.data.d && response.data.d.results)
                return response.data.d.results[0].Choices.results;
            else
                return [];
        });
    } catch (error) {
        console.error(error);
        return [];
    }
}

export async function Post(postUrl: string, itemData: any, itemType: string) {
    try {
        const headers = {
            ...SharePointHeaders,
            "X-RequestDigest": document.querySelector<HTMLInputElement>("#__REQUESTDIGEST")?.value || ""
        }
        const data = {
            ...itemData,
            __metadata: {
                type: itemType
            }
        }

        return await axios.post(postUrl, data, { ...RequestConfig, headers }).then(response => {
            return response;
        });
    } catch (error) {
        console.error(error);
        return [];
    }
}