import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";
import { useEffect, useState } from "react";
import { useFinancialStatementsPage } from "../../../context/AboutUs/FinancialStatements/FinancialStatements.context";
import { EstadoFinanciero } from "../../../context/AboutUs/FinancialStatements/FinancialStatements.models";
import { DocumentCard } from "../../../components/Cards/DocumentCard/documentCard";
import './EstadosFinancieros.scoped.css';

export const EstadosFinancieros = () => {
    const context = useFinancialStatementsPage();
    const [states, setStates] = useState<{ [key: string]: Array<EstadoFinanciero>; }>({});
    const [filteredStates, setFilteredStates] = useState<{ [key: string]: Array<EstadoFinanciero>; }>({});
    const [categories, setCategories] = useState<Array<string>>([]);
    const [categoryFilter, setCategoryFilter] = useState<string>("Todos");
    const [filterYears, setFilterYears] = useState(Array<string>());
    const [fileYear, setYearFilter] = useState("Todos");

    useEffect(() => {
        if (context.Estados && context.Estados.length > 0) {
            const newStates = context.Estados.reduce<{ [key: string]: Array<EstadoFinanciero>; }>((result: { [key: string]: Array<EstadoFinanciero>; }, currentItem: EstadoFinanciero) => {
                if (!result[currentItem.Ano])
                    result[currentItem.Ano] = [];
                result[currentItem.Ano].push(currentItem);
                return result;
            }, {});
            setStates(newStates);
            setFilteredStates(newStates);

            let categorias: Array<string> = [];
            let anos: Array<string> = [];
            context.Estados.forEach((estado: EstadoFinanciero) => {
                if (!categorias.includes(estado.Categoria))
                    categorias.push(estado.Categoria);
                if (!anos.includes(estado.Ano))
                    anos.push(estado.Ano);
            });
            setCategories(categorias);
            setFilterYears(anos);
        }
    }, [context.Estados]);

    useEffect(() => {
        let filteredResults: { [key: string]: Array<EstadoFinanciero>; } = {};

        if (context.Estados && context.Estados.length > 0)
            Object.keys(states).forEach((key: string) => {
                if (key == fileYear || fileYear == "Todos") {
                    if (categoryFilter === "Todos")
                        filteredResults[key] = states[key];
                    else
                        filteredResults[key] = states[key].filter((state => { return state.Categoria == categoryFilter; }))
                }
            });

        setFilteredStates(filteredResults);
    }, [categoryFilter, fileYear]);

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Nosotros",
            url: "/Nosotros"
        },
        {
            title: "Estados Financieros",
            url: "/Nosotros/EstadosFinancieros"
        }
    ];

    return (
        <article id="estados-financieros">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">

                <div className="intro">
                    <p>{context?.Intro?.Resumen}</p>
                </div>

                <div className="filtro-documentos">
                    <div className="datepicker-container">
                        <div className="datepicker">
                            <select id="document-year"
                                onChange={(e) => setYearFilter(e.target.value)}
                                value={fileYear} >
                                <option value="Todos" selected>Todos</option>
                                {
                                    filterYears.map((year: string) => {
                                        return (
                                            <option value={year} key={year}>{year}</option>
                                        );
                                    })
                                }
                            </select>
                            <label htmlFor="document-year">
                                <span className="title">
                                    <span className="icon-calendar"></span> Año
                                </span>
                                <span className="pl-1 selected-year">{fileYear}</span>
                            </label>
                        </div>
                    </div>
                    <div className="type-picker">
                        <ul className="document-types">
                            <li className={"document-type" + (categoryFilter == "Todos" ? " active" : "")}>
                                <button type="button"
                                    onClick={() => setCategoryFilter("Todos")}>
                                    Todos
                                </button>
                            </li>
                            {
                                categories?.map((category: string) => {
                                    return (
                                        <li key={category} className={"document-type" + (categoryFilter == category ? " active" : "")}>
                                            <button type="button"
                                                onClick={() => setCategoryFilter(category)}>
                                                {category}
                                            </button>
                                        </li>
                                    );
                                })
                            }
                        </ul>

                        <div className="form-container">
                            <label htmlFor="document-type-picker">Tipo de Estado Financiero</label>
                            <div className="custom-select-container">
                                <select name="document-type-picker"
                                    className="document-types"
                                    onChange={(e) => { setCategoryFilter(e.target.value) }}>
                                    <option value="Todos">Todos</option>
                                    {
                                        categories?.map((category: string) => {
                                            return (
                                                <option value={category}
                                                    key={category}>{category}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    Object.keys(filteredStates).map((key: string) => {
                        return (
                            <>
                                {
                                    filteredStates[key].length > 0 &&
                                    <div className="ano-group" key={key}>
                                        <h3 className="ano">{key}</h3>

                                        <ul className="documentos">
                                            {
                                                filteredStates[key].map((state: EstadoFinanciero) => {
                                                    return (
                                                        <li>
                                                            <DocumentCard
                                                                document={
                                                                    {
                                                                        Titulo: state.Categoria,
                                                                        Modified: state.Modified,
                                                                        Size: state.Size,
                                                                        Url: state.Url,
                                                                        Tipo: state.TipoArchivo
                                                                    }
                                                                }
                                                                key={state.Titulo} />
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                                }
                            </>
                        );
                    })
                }
            </div>
        </article>
    );
};