import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import axios from "axios";
import './CalculadoraPensiones.css';
import { useState } from "react";
import { PensionCalcResult } from "../../../context/Affiliates/Pension/Pension.models";
import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";
import { usePensionCalculatorPage } from "../../../context/Affiliates/Pension/Pension.context";

export const CalculadoraPensiones = () => {

    const context = usePensionCalculatorPage();
    const [showResults, setShowResults] = useState(false);
    const [calcResult, setCalcResult] = useState<PensionCalcResult | null>(null);
    const { control, register, handleSubmit, reset } = useForm();

    const onSubmit = (data: any) => {
        const anoPension = new Date().getFullYear() + (data.EdadRetiro - data.EdadActual);

        for (let prop in data)
            if (data[prop] == "" || data[prop] == null)
                data[prop] = 0;

        axios.post(`/_layouts/15/BRRD.AFP.SitePublico/CalculadoraPensionesController.aspx/GetDatosPension`,
            {
                request: data
            },
            {
                headers: {
                    "Accept": "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose"
                }
            }
        ).then(response => {
            const resultsBox = document.querySelector(".results-box");

            reset({
                MontoCci: "",
                SalarioActual: "",
                PorcentajeAporteVoluntario: "",
                EdadActual: "",
                EdadRetiro: ""
            });
            setShowResults(true);
            setCalcResult({ ... { AnoPension: anoPension }, ...response.data.d });

            if (resultsBox)
                resultsBox.scrollIntoView({ behavior: "smooth" });
        });
    };

    const moneyInput = (name: string, value: number, onChange: (...event: any[]) => void) => {
        return <NumberFormat
            id={name}
            name={name}
            placeholder={"RD$ 0.00"}
            prefix={"RD$ "}
            value={value}
            maxLength={15}
            decimalSeparator="."
            displayType="input"
            thousandsGroupStyle="thousand"
            thousandSeparator={true}
            allowNegative={false}
            onValueChange={(e: any) => { onChange(e.floatValue); }}
        />;
    };
    const ageInput = (name: string, value: number, onChange: (...event: any[]) => void) => {
        return <NumberFormat
            id={name}
            name={name}
            placeholder="0"
            value={value}
            maxLength={3}
            decimalSeparator="."
            displayType="input"
            thousandsGroupStyle="thousand"
            thousandSeparator={true}
            allowNegative={false}
            onValueChange={(e: any) => { onChange(e.floatValue); }}
        />;
    };
    const result = (value?: number) => {
        return <NumberFormat
            value={value}
            className="resultado"
            prefix="RD$ "
            decimalSeparator="."
            displayType="text"
            thousandsGroupStyle="thousand"
            thousandSeparator={true}
            decimalScale={2} />;
    };

    const pageBreadcrumbs = [{
        title: "AFP Reservas",
        url: "/"
    },
    {
        title: "Afiliados",
        url: "/Afiliados"
    },
    {
        title: "Calculadora de Pensiones",
        url: "/Afiliados/Pensiones"
    }];

    return (
        <section id="calculadora">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="info" dangerouslySetInnerHTML={{ __html: context?.Intro?.Resumen || "" }}></div>

                <div className="calc">
                    <div className="form">
                        <div className="field">
                            <label htmlFor="Sexo">Sexo</label>
                            <select id="Sexo"
                                {...register("Sexo", { required: true })}>
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                            </select>
                        </div>

                        <div className="field">
                            <label htmlFor="MontoCci">Monto acumulado en su CCI de su último Estado de Cuenta</label>
                            <Controller
                                control={control}
                                name="MontoCci"
                                render={({ field: { onChange, name, value } }) => (
                                    moneyInput(name, value, onChange)
                                )}
                                rules={{
                                    required: true,
                                    min: 1
                                }}
                            />
                        </div>

                        <div className="field">
                            <label htmlFor="SalarioActual">Salario actual (RD$)</label>
                            <Controller
                                control={control}
                                name="SalarioActual"
                                render={({ field: { onChange, name, value } }) => (
                                    moneyInput(name, value, onChange)
                                )}
                                rules={{
                                    required: true,
                                    min: 1
                                }}
                            />
                        </div>

                        <div className="field">
                            <label htmlFor="PorcentajeAporteVoluntario">Porcentaje extra del salario como aporte voluntario (%)</label>
                            <Controller
                                control={control}
                                name="PorcentajeAporteVoluntario"
                                render={({ field: { onChange, name, value } }) => (
                                    <NumberFormat
                                        id={name}
                                        name={name}
                                        placeholder="0 %"
                                        value={value}
                                        maxLength={3}
                                        suffix=" %"
                                        decimalSeparator="."
                                        displayType="input"
                                        thousandsGroupStyle="thousand"
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        onValueChange={(e: any) => { onChange(e.floatValue); }}
                                    />
                                )}
                            />
                        </div>

                        <div className="field">
                            <label htmlFor="EdadActual">Edad Actual</label>
                            <Controller
                                control={control}
                                name="EdadActual"
                                render={({ field: { name, value, onChange } }) => (
                                    ageInput(name, value, onChange)
                                )}
                                rules={{
                                    required: true,
                                    min: 1
                                }}
                            />
                        </div>

                        <div className="field">
                            <label htmlFor="EdadRetiro">Edad de Retiro Programada</label>
                            <Controller
                                control={control}
                                name="EdadRetiro"
                                render={({ field: { name, value, onChange } }) => (
                                    ageInput(name, value, onChange)
                                )}
                                rules={{
                                    required: true,
                                    min: 1
                                }}
                            />
                        </div>

                        <div className="submit-container">
                            <button type="button" className="primary-button"
                                onClick={handleSubmit(onSubmit)}>Calcular</button>
                        </div>
                    </div>

                    <div className={"results-box" + (showResults ? ' show' : '')}>
                        <h4>Resultado</h4>

                        <ul className="results">
                            <li className="result">
                                <span className="concepto">Año de Pensión</span>
                                <span className="resultado">{calcResult?.AnoPension}</span>
                            </li>
                            <li className="result joint">
                                <div className="joint-result">
                                    <span className="concepto">Monto constitutivo a la fecha de retiro</span>
                                    {result(calcResult?.MontoConstitutivo)}
                                </div>
                                <div className="joint-result">
                                    <span className="concepto">Aportes voluntarios</span>
                                    {result(calcResult?.MontoConstitutivoVoluntario)}
                                </div>
                            </li>
                            <li className="result">
                                <span className="concepto">Último salario</span>
                                {result(calcResult?.UltimoSalario)}
                            </li>
                            <li className="result joint">
                                <div className="joint-result">
                                    <span className="concepto">Monto pensión mensual</span>
                                    {result(calcResult?.MontoPensionMensual)}
                                </div>
                                <div className="joint-result">
                                    <span className="concepto">Aportes voluntarios</span>
                                    {result(calcResult?.MontoPensionMensualVoluntario)}
                                </div>
                            </li>
                            <li className="result joint">
                                <div className="joint-result">
                                    <span className="concepto">Tasa de reemplazo</span>
                                    {result(calcResult?.TasaReemplazo)}
                                </div>
                                <div className="joint-result">
                                    <span className="concepto">Aportes voluntarios</span>
                                    {result(calcResult?.TasaReemplazoVoluntario)}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}