import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Get } from "../../../util/sharepointRequests";
import { Activity } from "../Activities/Activities.models";
import { ActivityImage, ActivityPage } from "./Activity.models";

type ActivityPageProps = { children: ReactNode; };
const ActivityContext = createContext<ActivityPage | undefined>(undefined);

type Message =
    { type: 'actividad'; value: Activity; }
    | { type: 'imagenes'; value: Array<ActivityImage>; };

const reducer = (state: ActivityPage, message: Message) => {
    switch (message.type) {
        case 'actividad':
            return {
                ...state, Activity: message.value
            }
        case 'imagenes':
            return {
                ...state, Images: message.value
            }
        default:
            return { ...state };
    }
};

const ActivityContextProvider = ({ children }: ActivityPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as ActivityPage);
    const location = useLocation();
    const itemId = new URLSearchParams(location.search).get("aId") || new URLSearchParams(location.search).get("AId") || "1";

    useEffect(() => {
        fetchActivity();
        fetchDocuments();
    }, [location]);

    async function fetchActivity() {
        const data = await Get("/Publicaciones/_api/web/lists/GetByTitle('Actividades')/items?$select=Id,Orden,Activo,Title,Contenido,Categoria,FechaPublicacion,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1 and Id eq " + itemId)
            .then(response => {
                let result: Activity[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Contenido: item.Contenido,
                        Categoria: item.Categoria,
                        Portada: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        FechaPublicacion: new Date(item.FechaPublicacion)
                    });
                });

                return result;
            });

        if (data && data.length > 0)
            dispatch({ type: 'actividad', value: data[0] });
    }

    async function fetchDocuments() {
        const items = await Get(`/Publicaciones/_api/web/lists/GetByTitle('Actividades - Imagenes')/items?$select=Orden,Activo,Id,Title,Imagen_x0020_MiniaturaId,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1 and Actividad/ID eq ` + itemId)
            .then(response => {
                let result: ActivityImage[] = [];
                let thumbnailIds: Array<number> = [];

                response.forEach((document: any) => {
                    let thumbnail: any;

                    if (document.Imagen_x0020_MiniaturaId) {
                        thumbnail = response.find((t: any) => t.Id == document.Imagen_x0020_MiniaturaId);

                        if (thumbnail && thumbnail.Id) {
                            thumbnailIds.push(thumbnail.Id);
                        }
                    }

                    result.push({
                        Id: document.Id,
                        Title: document.Title ? document.Title : document.File.Name,
                        Thumbnail: ((thumbnail && thumbnail.Id) ? thumbnail.File.ServerRelativeUrl : document.File.ServerRelativeUrl),
                        Url: document.File.ServerRelativeUrl
                    });
                });

                return result.filter((document: ActivityImage) => thumbnailIds.indexOf(document.Id) < 0);
            });

        dispatch({ type: 'imagenes', value: items });
    }

    return (
        <ActivityContext.Provider value={state}>
            {children}
        </ActivityContext.Provider>
    );

};

const useActivity = () => {
    const context = useContext(ActivityContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useActivity, ActivityContextProvider };