import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get, GetTitles } from "../../../util/sharepointRequests";
import { PageIntro } from "../../Models/page.models";
import { CycleStep, JoinUsIntro, JoinUsPage, JoinUsVideo } from "./JoinUsPage.models";

type JoinUsPageProps = { children: ReactNode; };
const JoinUsPageContext = createContext<JoinUsPage | undefined>(undefined);

type Message = { type: 'intro'; value: JoinUsIntro; }
    | { type: 'videos'; value: Array<JoinUsVideo>; }
    | { type: 'beneficios'; value: Array<string>; }
    | { type: 'pasantiaIntro'; value: PageIntro; }
    | { type: 'ciclo'; value: Array<CycleStep>; }
    | { type: 'requisitos'; value: Array<string>; };

const reducer = (state: JoinUsPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'videos':
            return {
                ...state, Videos: message.value
            }
        case 'beneficios':
            return {
                ...state, Beneficios: message.value
            }
        case 'pasantiaIntro':
            return {
                ...state, PasantiaIntro: message.value
            }
        case 'ciclo':
            return {
                ...state, CicloEjecucion: message.value
            }
        case 'requisitos':
            return {
                ...state, RequisitosPasantia: message.value
            }
        default:
            return { ...state };
    }
};

const JoinUsPageContextProvider = ({ children }: JoinUsPageProps) => {

    const [joinUsPage, dispatch] = useReducer(reducer, {} as JoinUsPage);

    useEffect(() => {
        fetchIntro();
        fetchVideos();
        fetchBenefits();
        fetchInternshipIntro();
        fetchCycle();
        fetchRequirements();
    }, []);

    async function fetchIntro() {
        const intro = await Get(`/Nosotros/_api/web/lists/GetByTitle('Forma Parte - Intro')/items?$select=Activo,Title,Contenido,EnlaceFormulario&$filter=Activo eq 1`)
            .then(response => {
                let result: JoinUsIntro[] = [];

                response.forEach((items: any) => {
                    result.push({
                        Titulo: items.Title,
                        Resumen: items.Contenido,
                        EnlaceFormulario: items.EnlaceFormulario
                    });
                });

                return result[0];
            });

        if (intro)
            dispatch({ type: 'intro', value: intro });
    }
    async function fetchVideos() {
        const videos = await Get(`/Nosotros/_api/web/lists/GetByTitle('Forma Parte - Videos')/items?$select=Activo,Orden,Title,Enlace&$filter=Activo eq 1&$orderby=Orden`)
            .then(response => {
                let result: Array<JoinUsVideo> = [];

                response.forEach((item: any) => {
                    result.push({
                        titulo: item.Title,
                        enlace: item.Enlace.replace("watch?v=", "embed/")
                    });
                });

                return result;
            });

        if (videos.length > 0)
            dispatch({ type: 'videos', value: videos });
    }
    async function fetchBenefits() {
        const benefits = await GetTitles(`/Nosotros/_api/web/lists/GetByTitle('Forma Parte - Beneficios')/items?$select=Activo,Orden,Title&$filter=Activo eq 1&$orderby=Orden`);

        if (benefits.length > 0)
            dispatch({ type: 'beneficios', value: benefits });
    }
    async function fetchInternshipIntro() {
        const intro = await Get(`/Nosotros/_api/web/lists/GetByTitle('Forma Parte - Pasantia Intro')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1`)
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((items: any) => {
                    result.push({
                        Titulo: items.Title,
                        Resumen: items.Contenido
                    });
                });

                return result[0];
            });

        if (intro)
            dispatch({ type: 'pasantiaIntro', value: intro });
    }
    async function fetchCycle() {
        const cycle = await Get(`/Nosotros/_api/web/lists/GetByTitle('Forma Parte - Pasantia Ciclo')/items?$select=Activo,Orden,Title,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1&$orderby=Orden`)
            .then(response => {
                let result: Array<CycleStep> = [];

                response.forEach((item: any) => {
                    result.push({
                        titulo: item.Title,
                        icon: (item.AttachmentFiles && item.AttachmentFiles.results && item.AttachmentFiles.results.length > 0 ? item.AttachmentFiles.results[0].ServerRelativeUrl : "")
                    });
                });

                return result;
            });

        if (cycle.length > 0)
            dispatch({ type: 'ciclo', value: cycle });
    }
    async function fetchRequirements() {
        const requirements = await GetTitles(`/Nosotros/_api/web/lists/GetByTitle('Forma Parte - Pasantia Requisitos')/items?$select=Activo,Orden,Title&$filter=Activo eq 1&$orderby=Orden`);

        if (requirements.length > 0)
            dispatch({ type: 'requisitos', value: requirements });
    }

    return (
        <JoinUsPageContext.Provider value={joinUsPage}>
            {children}
        </JoinUsPageContext.Provider>
    );

};

const useJoinUsPage = () => {
    const context = useContext(JoinUsPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useJoinUsPage, JoinUsPageContextProvider };