import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageHeaders } from "../components/PageHeaders/PageHeaders";
import { ContactPageContextProvider } from "../context/Contact/Contact.context";
import { HomeContextProvider } from "../context/Home/home.context";
import { Afiliados } from "../hoc/Afiliados/Afiliados";
import { Contacto } from "../hoc/Contacto/Contacto";
import { Empleador } from "../hoc/Empleador/Empleador";
import { Home } from "../hoc/Home/Home";
import { Nosotros } from "../hoc/Nosotros/Nosotros";
import { Publicaciones } from "../hoc/Publicaciones/Publicaciones";

export const Router = () => {
    return (
        <>
            <PageHeaders />

            <Routes>
                <Route path="/" element={
                    <React.Suspense fallback={<>...</>}>
                        <HomeContextProvider>
                            <Home />
                        </HomeContextProvider>
                    </React.Suspense>
                }>
                </Route>
                <Route path="/Afiliados/*" element={
                    <React.Suspense fallback={<>...</>}>
                        <Afiliados />
                    </React.Suspense>
                }>
                </Route>
                <Route path="/Empleador/*" element={
                    <React.Suspense fallback={<>...</>}>
                        <Empleador />
                    </React.Suspense>
                }>
                </Route>
                <Route path="/Nosotros/*" element={
                    <React.Suspense fallback={<>...</>}>
                        <Nosotros />
                    </React.Suspense>
                }>
                </Route>
                <Route path="/Publicaciones/*" element={
                    <React.Suspense fallback={<>...</>}>
                        <Publicaciones />
                    </React.Suspense>
                }>
                </Route>
                <Route path="/Contactanos" element={
                    <React.Suspense fallback={<>...</>}>
                        <ContactPageContextProvider>
                            <Contacto />
                        </ContactPageContextProvider>
                    </React.Suspense>
                }>
                </Route>

                <Route path="/404" element={<Navigate replace to="/" />} />
                <Route path="/Paginas/PageNotFoundError.aspx" element={<Navigate replace to="/" />} />
            </Routes>
        </>
    );
};