import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Get } from "../../../util/sharepointRequests";
import { PressNote } from "../publications.models";
import { PressNotePage } from "./PressNote.models";

type PressNotePageProps = { children: ReactNode; };
const PressNoteContext = createContext<PressNotePage | undefined>(undefined);

type Message =
    { type: 'noticia'; value: PressNote; }
    | { type: 'noticiaRelacionadas'; value: Array<PressNote>; }

const reducer = (state: PressNotePage, message: Message) => {
    switch (message.type) {
        case 'noticia':
            return {
                ...state, PressNote: message.value
            }
        case 'noticiaRelacionadas':
            return {
                ...state, RelatedNews: message.value
            }
        default:
            return { ...state };
    }
};

const PressNoteContextProvider = ({ children }: PressNotePageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as PressNotePage);
    const location = useLocation();
    const itemId = new URLSearchParams(location.search).get("pId") || new URLSearchParams(location.search).get("PId") || "1";

    useEffect(() => {
        fetchPressNote();
        fetchRelatedPressNotes();
    }, [location]);

    async function fetchPressNote() {
        const pressNote = await Get("/Publicaciones/_api/web/lists/GetByTitle('Notas de Prensa')/items?$select=Id,Activo,Title,Contenido,FechaPublicacion,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1 and ID eq " + itemId)
            .then(response => {
                let result: PressNote[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Contenido: item.Contenido,
                        Imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        FechaPublicacion: new Date(item.FechaPublicacion)
                    });
                });

                return result[0];
            });

        if (pressNote)
            dispatch({ type: 'noticia', value: pressNote });
    }

    async function fetchRelatedPressNotes() {
        const relatedPressNotes = await Get("/Publicaciones/_api/web/lists/GetByTitle('Notas de Prensa')/items?$select=Id,Activo,Title,Resumen,FechaPublicacion,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1&$orderby=ID desc&$top=3")
            .then(response => {
                let result: PressNote[] = [];

                response.forEach((pressNote: any) => {
                    result.push({
                        Id: pressNote.Id,
                        Titulo: pressNote.Title ? pressNote.Title : pressNote.File.Name,
                        Resumen: pressNote.Resumen,
                        Imagen: pressNote.Attachments ? pressNote.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        FechaPublicacion: new Date(pressNote.FechaPublicacion)
                    });
                });

                return result;
            });

        dispatch({ type: 'noticiaRelacionadas', value: relatedPressNotes });
    }

    return (
        <PressNoteContext.Provider value={state}>
            {children}
        </PressNoteContext.Provider>
    );

};

const usePressNote = () => {
    const context = useContext(PressNoteContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { usePressNote, PressNoteContextProvider };