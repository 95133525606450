import { BannerTop } from '../../../components/BannerTop/BannerTop';
import { RequestCard } from '../../../components/Cards/RequestCard/requestCard';
import { useRequests } from '../../../context/Employer/Requests/Requests.context';
import { RequestInfo } from '../../../context/Employer/Requests/Requests.models';
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import './Solicitudes.scoped.css';

export const Solicitudes = () => {
    const context = useRequests();
    const pageBreadcrumbs = [{
        title: "AFP Reservas",
        url: "/"
    },
    {
        title: "Empleador",
        url: "/Empleador"
    },
    {
        title: "Solicitudes",
        url: "/Empleador/Solicitudes"
    }];

    return (
        <article id="solicitudes">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="solicitudes-container">
                    {
                        context?.Requests?.map((request: RequestInfo) => {
                            return (
                                <RequestCard key={request.Id} request={request} />
                            );
                        })
                    }
                </div>
            </div>
        </article>
    );
};