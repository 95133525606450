import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ActivitiesContextProvider } from "../../context/Publications/Activities/Activities.context";
import { ActivityContextProvider } from "../../context/Publications/Activity/Activity.context";
import { MemoriesPageContextProvider } from "../../context/Publications/Memories/Memories.context";
import { PressNoteContextProvider } from "../../context/Publications/PressNote/PressNote.context";
import { PressNotesContextProvider } from "../../context/Publications/PressNotes/PressNotes.context";
import { Actividad } from "./Actividad/Actividad";
import { Actividades } from "./Actividades/Actividades";
import { Memorias } from "./Memorias/Memorias";
import { NotaPrensa } from "./NotaPrensa/NotaPrensa";
import { NotasPrensa } from "./NotasPrensa/NotasPrensa";

export const Publicaciones = () => {

    return (
        <Routes>
            <Route path="" element={<Navigate replace to="Actividades" />} />
            <Route path="Actividades" element={
                <React.Suspense fallback={<>...</>}>
                    <ActivitiesContextProvider>
                        < Actividades />
                    </ActivitiesContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Actividad" element={
                <React.Suspense fallback={<>...</>}>
                    <ActivityContextProvider>
                        < Actividad />
                    </ActivityContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Memorias" element={
                <React.Suspense fallback={<>...</>}>
                    <MemoriesPageContextProvider>
                        < Memorias />
                    </MemoriesPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="NotasPrensa" element={
                <React.Suspense fallback={<>...</>}>
                    <PressNotesContextProvider>
                        < NotasPrensa />
                    </PressNotesContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="NotaPrensa" element={
                <React.Suspense fallback={<>...</>}>
                    <PressNoteContextProvider>
                        < NotaPrensa />
                    </PressNoteContextProvider>
                </React.Suspense>
            }>
            </Route>
        </Routes>
    );
};