import { Link } from "react-router-dom";
import { DynamicImage } from "../../Images/DynamicImage/dynamicImage";
import "./briefCard.css";

export interface BriefCardProps {
    className?: string;
    title: string;
    summary: string;
    image: string;
    url: string;
    urlText: string;
}

export function BriefCard(props: BriefCardProps) {
    return (
        <div className="brief-card">
            <div className="img-container">
                <DynamicImage src={props.image} alt={"Imagen de " + props.title} />
            </div>
            <div className="info-container">
                <h4 className="title">{props.title}</h4>
                <div className="summary">
                    <p>{props.summary}</p>
                </div>
                <div className="link-container">
                    <Link to={props.url}>
                        {props.urlText}
                        <span className="icon-line-arrow-right"></span>
                    </Link>
                </div>
            </div>
        </div>
    );
}
