import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";
import { useAboutUsPage } from "../../../context/AboutUs/AboutUs/AboutUs.context";

import './QuienesSomos.css';
import { Award, BoardMember, DirectiveCommitteeMember, Identity } from "../../../context/AboutUs/AboutUs/AboutUs.models";
import { useEffect, useState } from "react";

export const QuienesSomos = () => {
    const context = useAboutUsPage();
    const [premios, setPremios] = useState<{ [key: string]: Array<Award>; }>();

    useEffect(() => {
        if (context && context.Premios && context.Premios.length > 0)
            setPremios(
                context.Premios.reduce(
                    (result: { [key: string]: Array<Award>; }, award: Award) => {
                        if (!result[award.categoria])
                            result[award.categoria] = [];
                        result[award.categoria].push(award);
                        return result;
                    },
                    {}
                )
            );
    }, [context?.Premios]);

    function scrollYear(year: string) {
        const yearsSelector = document.querySelector(".years-list");

        if (yearsSelector) {
            if (parseInt(year) > 0) {
                const yearNode = document.querySelector<HTMLElement>(".year-item[data-year='" + year + "']");

                if (yearNode)
                    yearsSelector.scroll({
                        top: 0,
                        left: yearNode.offsetLeft,
                        behavior: "smooth"
                    });
            }
        }
    }

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Nosotros",
            url: "/Nosotros"
        },
        {
            title: "Familia Reservas",
            url: "/Nosotros/Familia"
        }
    ];

    return (
        <article id="quienes-somos">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            {
                (context?.Intro && (context?.Intro?.Imagen || context?.Intro?.Resumen)) &&

                <div className="intro">
                    <div className="container">
                        <div className="img-container">
                            <img src={context?.Intro?.Imagen} alt={context?.Intro?.Titulo} />
                        </div>
                        <div className="text-container" dangerouslySetInnerHTML={{ __html: context?.Intro?.Resumen || "" }}></div>
                    </div>
                </div>
            }

            {
                (context?.Identidad && context?.Identidad?.length > 0) &&

                <div className="values">
                    <div className="container">
                        <ul className="values-list">
                            {
                                context?.Identidad?.map((identity: Identity) => {
                                    return (
                                        <li key={identity.titulo}>
                                            <div className="icon-container">
                                                <img src={identity.icono} alt={"Ícono " + identity.titulo} />
                                            </div>

                                            <h3>{identity.titulo}</h3>

                                            <div dangerouslySetInnerHTML={{ __html: identity.cuerpo || "" }}></div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            }

            {
                (context?.PalabrasGerente && context?.PalabrasGerente.cuerpo) &&

                <div id="manager-words">
                    <div className="container">
                        <h2 className="main-title">Palabras de Nuestro Gerente General</h2>

                        <div className="quote-container">
                            <div className="image-container">
                                <img src={context?.PalabrasGerente?.imagen} alt="Foto del Manager" />
                            </div>

                            <div className="background"></div>

                            <div className="quote">
                                <div className="text" dangerouslySetInnerHTML={{ __html: context?.PalabrasGerente?.cuerpo }}></div>

                                <div className="signature">
                                    <span className="name">{context?.PalabrasGerente?.nombre}</span>
                                    <span className="position">{context?.PalabrasGerente?.posicion}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                (context?.MiembrosComiteDirectivo && context?.MiembrosComiteDirectivo.length > 0) &&

                <div className="committee-members">
                    <div className="container">
                        <h2 className="main-title">Comité Directivo de AFP Reservas</h2>

                        <div className="members-container">
                            {
                                context?.MiembrosComiteDirectivo?.map((miembro: DirectiveCommitteeMember) => {
                                    return (
                                        <div className="member" key={miembro.nombre}>
                                            <div className="img-container">
                                                <img src={miembro.imagen} alt={`Imagen de ${miembro.nombre}`} />
                                            </div>

                                            <div className="member-info">
                                                <span className="name">{miembro.nombre}</span>
                                                <span className="position">{miembro.cargo}</span>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            }

            <div className="board-directors">
                <div className="container">
                    <h2 className="main-title">Consejo de Directores</h2>

                    <div className="intro-text" dangerouslySetInnerHTML={{ __html: context?.ConsejoIntro?.Resumen || "" }}></div>

                    {
                        (context?.MiembrosConsejo && context?.MiembrosConsejo.length > 0) &&

                        <>
                            <h3 className="second-title">Miembros del Consejo de Directores ({context?.ConsejoIntro?.periodo})</h3>

                            <ul className="bullet-list">

                                {
                                    context?.MiembrosConsejo?.map((miembro: BoardMember) => {
                                        return (
                                            <li key={miembro.nombre}>
                                                <span className="name">{miembro.nombre}</span>
                                                <span className="position">{miembro.posicion}</span>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </>
                    }
                    {
                        (context?.Accionistas && context?.Accionistas?.length > 0) &&
                        <>
                            <h3 className="main-title">Accionistas</h3>

                            <ul className="stakeholders">
                                {
                                    context?.Accionistas?.map((accionista: string) => {
                                        return (
                                            <li key={accionista}>{accionista}</li>
                                        );
                                    })
                                }
                            </ul>
                        </>
                    }
                </div>
            </div>

            {
                (context?.Premios) &&
                <div className="awards">
                    <div className="container">
                        <h3>Premios y Reconocimientos</h3>

                        <div className="years-selector">
                            <div className="line"></div>
                            <ul className="years">
                                {
                                    premios &&
                                    Object.keys(premios).map((ano: string) => {
                                        return (
                                            <li className="year" data-year={ano} key={ano}>
                                                <button type="button"
                                                    onClick={() => scrollYear(ano)}>
                                                    <span className="date">{ano}</span>
                                                    <span className="point"></span>
                                                </button>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>

                        <ul className="years-list">
                            {
                                premios &&
                                Object.keys(premios).map((ano: string) => {
                                    return (
                                        <li className="year-item" data-year={ano} key={ano}>
                                            <div className="year">
                                                <div className="date">
                                                    {ano}
                                                </div>

                                                <div className="awards">
                                                    {
                                                        premios[ano].map((award: Award) => {
                                                            return (
                                                                <div className="award" key={award.titulo}>
                                                                    <div className="info">
                                                                        <div className="logo">
                                                                            <img src={award.imagen} alt={"Logo " + award.titulo} />
                                                                        </div>

                                                                        <h4 className="title">{award.titulo}</h4>

                                                                        <div className="sub-title">{award.ano}</div>

                                                                        <p className="desc">{award.descripcion}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            }

        </article >
    );
};