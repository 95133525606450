import { BannerTop } from '../../../components/BannerTop/BannerTop';
import { QuestionsContainer } from '../../../containers/QuestionsContainer/questionsContainer';
import { useFaqAffiliate } from '../../../context/Affiliates/Faq/Faq.context';
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import './PreguntasFrecuentes.css';

export const PreguntasFrecuentesAfiliados = () => {
    const context = useFaqAffiliate();
    const pageBreadcrumbs = [{
        title: "AFP Reservas",
        url: "/"
    },
    {
        title: "Afiliados",
        url: "/Afiliados"
    },
    {
        title: "Preguntas Frecuentes",
        url: "/Afiliados/PreguntasFrecuentes"
    }];

    return (
        <article id="faq">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>
            <div className="container">
                <div className="intro">
                    {context?.Intro?.Resumen}
                </div>

                <QuestionsContainer questions={context.Questions} />
            </div>
        </article>
    );
};