import { Link } from "react-router-dom";
import { useFooterPage } from "../../context/Global/Footer/footer.context";
import { FooterNavLink, RelevantLink } from "../../context/Global/Footer/footer.models";
import { StaticImage } from "../Images/StaticImage/staticImage";
import "./footer.css";

export function Footer() {
    const context = useFooterPage();

    const getNavChildren = (navItem: FooterNavLink) => {
        return navItem.children?.map((child: FooterNavLink) => {
            return (
                <li className="sub-menu-item" key={child.title}>
                    {
                        !child.externo &&
                        <Link to={child.link}>{child.title}</Link>
                    }
                    {
                        child.externo &&
                        <a href={child.link} target="_blank">{child.title} <span className="icon-link"></span></a>
                    }
                </li>
            );
        })
    };
    const getFeaturedLinks = () => {
        return context?.EnlacesInteres?.map((enlace: RelevantLink) => {
            return (
                <li className="sub-menu-item" key={`${enlace.title}`}>
                    <a href={enlace.enlace} target="_blank">
                        {enlace.title}
                        <span className="icon-link"></span>
                    </a>
                </li>
            );
        })
    }

    return (
        <footer id="footer">
            <div className="container">
                <div className="nav-container">
                    <div className="contact-info">
                        <ul className="social-media">
                            <li>
                                <a href="https://twitter.com/AFPReservasRD" target="_blank">
                                    <span className="icon-twitter"></span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/afpreservasrd" target="_blank">
                                    <span className="icon-instagram"></span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/AFPReservasOficial" target="_blank">
                                    <span className="icon-facebook"></span>
                                </a>
                            </li>
                        </ul>

                        <div className="section contact">
                            <h5>Contactos</h5>
                            <div className="detail">
                                <span className="icon-phone"></span>
                                <a href="mailto:servicioalcliente@afpreservas.com">servicioalcliente@afpreservas.com</a>
                            </div>
                            <div className="detail">
                                <span className="icon-phone"></span>
                                <a href="tel:8099607000">(809) 960 - 7000​</a>
                            </div>
                        </div>

                        <div className="section">
                            <h5>Encuentra una oficina próxima a ti</h5>

                            <Link to="/Contactanos" className="primary-button-outline">Ver nuestras oficinas</Link>
                        </div>

                        <div className="section">
                            <h5>¿Deseas afiliarte a nuestra AFP?</h5>

                            <a href="https://clientes.afpreservas.com/Registro/PreAfiliacion" className="primary-button-outline">Afíliate con AFP Reservas aquí</a>
                        </div>

                        <div className="section">
                            <h5>Línea de Ética</h5>

                            <a href="https://www.banreservas.com/Pages/Linea-Etica.aspx" className="primary-button-outline">Línea de Ética</a>
                        </div>

                    </div>

                    <ul className="desktop-site-map desktop-item">
                        {
                            context?.Navegacion?.map((navItem: FooterNavLink) => {
                                return (
                                    <li className="menu-item" key={`d-${navItem.title}`}>
                                        <h4>{navItem.title}</h4>

                                        <ul className="sub-menu">
                                            {getNavChildren(navItem)}
                                        </ul>
                                    </li>
                                );
                            })
                        }
                    </ul>

                    <ul className="mobile-site-map mobile-item">
                        {
                            context?.Navegacion?.map((navItem: FooterNavLink) => {
                                return (
                                    <li className="tab" key={`m-${navItem.title}`}>
                                        <input type="checkbox" id={navItem.title.replace(/ /g, '-').toLowerCase() + "-mobile-check"} className="tab-check" />
                                        <label className="tab-label" htmlFor={navItem.title.replace(/ /g, '-').toLowerCase() + "-mobile-check"}>
                                            {navItem.title}
                                            <span className="icon-container">
                                                <span className="icon-triangle-arrow-right"></span>
                                            </span>
                                        </label>

                                        <ul className="tab-content sub-menu">
                                            {getNavChildren(navItem)}
                                        </ul>
                                    </li>
                                );
                            })
                        }
                        <li className="tab">
                            <input type="checkbox" id="enlaces-mobile-check" className="tab-check" />
                            <label className="tab-label" htmlFor="enlaces-mobile-check">
                                Enlaces de Interés
                                <span className="icon-container">
                                    <span className="icon-triangle-arrow-right"></span>
                                </span>
                            </label>

                            <ul className="tab-content sub-menu">
                                {getFeaturedLinks()}
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="second-row-container desktop-item">
                    <div></div>
                    <div className="featured-links">
                        <h4>Enlaces de Interés</h4>

                        <ul>
                            {getFeaturedLinks()}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-band">
                <div className="container">
                    <StaticImage src="/images/logo-blanco.svg" alt="Logo AFP Reservas" className="logo" />
                    <div className="brand-info">
                        <ul className="links">

                            <li><a className="link" href={context?.EnlaceTerminos}>Términos y Condiciones</a></li>
                            <li><a className="link" href={context?.EnlacePoliticasPrivacidad}>Políticas de Privacidad</a></li>
                            <li> <span className="link">® {(new Date()).getFullYear()}, AFP Reservas</span></li>
                        </ul>
                        <p>
                            Miembros de ADAFP - Asociación Dominicana de Administradoras de
                            Fondos de Pensiones.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
