import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";

import './Familia.css';
import { useFamilyPage } from "../../../context/AboutUs/Family/Family.context";
import { Filial } from "../../../context/AboutUs/Family/Family.models";

export const Familia = () => {
    const context = useFamilyPage();

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Nosotros",
            url: "/Nosotros"
        },
        {
            title: "Familia Reservas",
            url: "/Nosotros/Familia"
        }
    ];

    return (
        <article id="familia-reservas">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="intro">
                    {context?.Intro?.Resumen}
                </div>

                <ul className="filiales">
                    {
                        context.Filiales?.map((filial: Filial) => {
                            return (
                                <li className="filial" key={filial.Titulo}>
                                    <a href={filial.Url} target="_blank">
                                        <div className="img-container">
                                            <img src={filial.Imagen} alt={"Logo " + filial.Titulo} className="logo" />
                                        </div>
                                        <div className="card-info">
                                            <span className="text">{filial.Titulo}</span>
                                            <span className="icon-link"></span>
                                        </div>
                                    </a>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </article>
    );
};