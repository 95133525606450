import axios from "axios";
import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { FooterLink, FooterNavLink, FooterPage, RelevantLink } from "./footer.models";

declare const window: any;

type FooterPagePageProps = { children: ReactNode; };
const FooterPageContext = createContext<FooterPage | undefined>(undefined);

type Message =
    { type: 'navigation'; value: FooterNavLink[]; }
    | { type: 'links'; value: RelevantLink[]; }
    | { type: 'termsLink'; value: string; }
    | { type: 'privacyLink'; value: string; };

const reducer = (state: FooterPage, message: Message) => {
    switch (message.type) {
        case 'navigation':
            return {
                ...state, Navegacion: message.value
            }
        case 'links':
            return {
                ...state, EnlacesInteres: message.value
            }
        case 'termsLink':
            return {
                ...state, EnlaceTerminos: message.value
            }
        case 'privacyLink':
            return {
                ...state, EnlacePoliticasPrivacidad: message.value
            }
        default:
            return { ...state };
    }
};

const FooterPageContextProvider = ({ children }: FooterPagePageProps) => {

    const [context, dispatch] = useReducer(reducer, {} as FooterPage);

    useEffect(() => {
        fetchNavigation();
        fetchLinks();
        fetchFooterLinks();
    }, []);

    async function fetchNavigation() {
        try {
            const footerTermsetInput = document.querySelector<HTMLInputElement>("#footer-termset-id");
            const footerTermsetName = document.querySelector<HTMLInputElement>("#footer-termset-name");
            const footerTermsetGroup = document.querySelector<HTMLInputElement>("#footer-termset-group");
            const footerTermsetSet = document.querySelector<HTMLInputElement>("#footer-termset-set");

            if (footerTermsetInput && footerTermsetInput.value) {
                const digest = document.querySelector<HTMLInputElement>("#__REQUESTDIGEST");

                if (digest && digest.value)
                    return await axios.post("/_layouts/15/BRRD.AFP.SitePublico/TermStoreController.aspx/GetTerms",
                        {
                            request: {
                                Name: footerTermsetName?.value,
                                Group: footerTermsetGroup?.value,
                                Set: footerTermsetSet?.value
                            }
                        },
                        {
                            headers: {
                                "Accept": "application/json;odata=verbose",
                                "Content-Type": "application/json;odata=verbose",
                            }
                        }
                    ).then(response => {
                        let result: FooterNavLink[] = [];

                        if (response.status == 200 && response.data && response.data.d && response.data.d)
                            result = parseTerms(response.data.d);
                        dispatch({ type: "navigation", value: result });
                    });
                else
                    return setTimeout(fetchNavigation, 100);
            }

        } catch (error) {
            console.log(error);
        }
    }

    async function fetchLinks() {
        const links = await Get("/_api/web/lists/GetByTitle('Enlaces Interes')/items?$select=Orden,Activo,Title,Enlace&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: RelevantLink[] = [];

                response.forEach((item: any) => {
                    result.push({
                        title: item.Title,
                        enlace: item.Enlace
                    });
                });

                return result;
            });

        dispatch({ type: 'links', value: links });
    }

    async function fetchFooterLinks() {
        const footerLinks = await Get("/_api/web/lists/GetByTitle('Enlaces Footer')/items?$select=Activo,TipoEnlace,Enlace&$filter=Activo eq 1")
            .then(response => {
                let result: FooterLink[] = [];

                response.forEach((item: any) => {
                    result.push({
                        type: item.TipoEnlace,
                        enlace: item.Enlace
                    });
                });

                return result;
            });

        const termsLink = footerLinks.find(link => link.type == "Terminos y Condiciones");
        if (termsLink)
            dispatch({ type: 'termsLink', value: termsLink.enlace });

        const privacyLink = footerLinks.find(link => link.type == "Politicas de Privacidad");
        if (privacyLink)
            dispatch({ type: 'privacyLink', value: privacyLink.enlace });
    }

    function parseTerms(terms: any) {
        let result: Array<FooterNavLink> = [];

        terms.forEach((term: any) => {
            result.push({
                title: term.Title || "",
                link: (term.CustomProperties.hasOwnProperty("Enlace") ? term.CustomProperties.Enlace : null) || term.URL || "",
                externo: term.CustomProperties.hasOwnProperty("Externo") || false,
                classes: term.CustomProperties.LinkClasses || "",
                children: parseTerms(term.Children)
            });
        });

        return result;
    }

    return (
        <FooterPageContext.Provider value={context}>
            {children}
        </FooterPageContext.Provider>
    );

};

const useFooterPage = () => {
    const context = useContext(FooterPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useFooterPage, FooterPageContextProvider };