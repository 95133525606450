import { useState } from 'react';
import { BannerTop } from '../../../components/BannerTop/BannerTop';
import { useContributionsCalculatorPage } from '../../../context/Affiliates/Contributions/Contributions.context';
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import NumberFormat from 'react-number-format';
import './CaculadoraAportes.css';

export const CalculadoraAportes = () => {
    const context = useContributionsCalculatorPage();
    const [salary, setSalary] = useState<number>();

    const pageBreadcrumbs = [{
        title: "AFP Reservas",
        url: "/"
    },
    {
        title: "Afiliados",
        url: "/Afiliados"
    },
    {
        title: "Calculadora Aportes",
        url: "/Afiliados/Aportes"
    }];

    return (
        <article id="calculadora" >
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="info" dangerouslySetInnerHTML={{ __html: context.Intro?.Resumen || "" }}></div>

                <div className="calc">
                    <div className="form">
                        <div className="field">
                            <label htmlFor="salario">Ingrese su salario</label>
                            <NumberFormat
                                id="salario"
                                placeholder="RD$ 000,000.00"
                                value={salary}
                                onValueChange={(e: any) => setSalary(e.floatValue)}
                                maxLength={15}
                                prefix="RD$ "
                                decimalSeparator="."
                                displayType="input"
                                thousandsGroupStyle="thousand"
                                thousandSeparator={true}
                                allowNegative={false}
                            />
                        </div>

                        {/* <div className="submit-container">
                            <button type="button" className="primary-button">Calcular</button>
                        </div> */}
                    </div>

                    <div className="results-box show">
                        <h4>Distribución de Aportes</h4>

                        <ul className="results">
                            <li className="result">
                                <span className="concepto">Afiliado: (<strong>2.87%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.0287}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                            <li className="result">
                                <span className="concepto">Empleador: (<strong>7.1%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.071}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                        </ul>
                    </div>

                    <div className="results-box show">
                        <h4>Partidas</h4>

                        <ul className="results">
                            <li className="result">
                                <span className="concepto">Total: (<strong>9.97%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.0997}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                            <li className="result">
                                <span className="concepto">Cuenta Personal: (<strong>8.4%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.084}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                            <li className="result">
                                <span className="concepto">Seguro de vida del afiliado: (<strong>0.95%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.0095}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                            <li className="result">
                                <span className="concepto">Fondo de Solidaridad Social: (<strong>0.4%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.004}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                            <li className="result">
                                <span className="concepto">Operación de la TSS: (<strong>0.1%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.001}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                            <li className="result">
                                <span className="concepto">Operación de la Superintendencia: (<strong>0.07%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.0007}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                            <li className="result">
                                <span className="concepto">Operación de la DIDA: (<strong>0.05%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.0005}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                        </ul>
                    </div>

                    <div className="final-result-box">
                        <ul className="results">
                            <li className="result">
                                <span className="concepto">Total de aporte: (<strong>9.97%</strong>)</span>
                                <NumberFormat
                                    value={(salary || 0) * 0.097}
                                    className="resultado"
                                    prefix="RD$ "
                                    decimalSeparator="."
                                    displayType="text"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator={true}
                                    decimalScale={2} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </article >
    );
};