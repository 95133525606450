import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { EffectivenessGraph } from "../../components/Cifras/EffectivenessGraph/effectivenessGraph";
import { ImageSlider } from "../../components/ImageSlider/imageSlider";
import { PageHeaders } from "../../components/PageHeaders/PageHeaders";
import { PressNoteCardContainer } from "../../containers/PressNoteCardContainer/pressNoteCardContainer";
import { useHome } from "../../context/Home/home.context";
import { HomeBanner, HomeOficina } from "../../context/Home/home.models";

import './home.css';

export const Home = () => {
    const context = useHome();

    const mainSlider = React.createRef<Splide>();
    const thumbSlider = React.createRef<Splide>();

    useEffect(() => {
        if (mainSlider && mainSlider.current && thumbSlider && thumbSlider.current && thumbSlider.current.splide)
            mainSlider.current.sync(thumbSlider.current.splide);
    }, []);


    return (
        <article>
            <PageHeaders title="Inicio" />

            <ImageSlider
                slides={
                    context?.Banner?.map(
                        (slide: HomeBanner) => {
                            return {
                                Title: slide.Titulo,
                                Content: slide.Contenido,
                                ImagenDesktop: slide.Imagen,
                                ImagenMobile: slide.ImagenMobile,
                                Url: slide.Url,
                                TextUrl: slide.TextoEnlace
                            }
                        }
                    )
                }
            ></ImageSlider>

            <section id="signup-bar">
                <div className="container">
                    <p className="history">{context?.BarraAfiliacion}</p>
                    {
                        context?.EnlaceAfiliacion &&
                        <a href={context?.EnlaceAfiliacion} className="primary-button" target="_blank">Afíliate con AFP Reservas aquí</a>
                    }
                </div>
            </section>

            <section id="news-highlights">
                <div className="container">
                    <h2>Notas de Prensa</h2>

                    <PressNoteCardContainer pressNoteCards={context?.NotasPrensa}></PressNoteCardContainer>

                    <div className="link-container">
                        <Link to="/Publicaciones/NotasPrensa" className="primary-button-outline">Ver todas las noticias</Link>
                    </div>
                </div>
            </section>

            <section id="app-download">
                <div className="container">
                    <h1 className="mobile-item">Descarga nuestra APP</h1>

                    <img className="celulares" src={context?.ImagenApp} alt="Imágenes Celulares con App AFP Reservas" />

                    <div className="app-info">
                        <h2 className="section-title desktop-item">Descarga nuestra APP</h2>

                        <p className="section-desc big-text">{context?.TextoApp}</p>

                        <div className="download-links">
                            <div className="app-links">
                                {
                                    (context?.EnlaceGoogle && context?.EnlaceGoogle.Url) &&
                                    <a href={context?.EnlaceGoogle?.Url} target="_blank">
                                        <img src={context?.EnlaceGoogle?.Imagen} alt={context?.EnlaceGoogle?.Titulo} />
                                    </a>
                                }
                                {
                                    (context?.EnlaceApple && context?.EnlaceApple.Url) &&
                                    <a href={context?.EnlaceApple?.Url} target="_blank">
                                        <img src={context?.EnlaceApple?.Imagen} alt={context?.EnlaceApple?.Titulo} />
                                    </a>
                                }
                            </div>
                            <div className="qr-code">
                                <img src={context?.CodigoQR?.Imagen} alt="Código QR de descarga" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section id="appointment-link">
                <div className="container">
                    <div className="appointment-info">
                        <h1>Agenda tus citas</h1>
                        <p className="big-text desktop-item">
                            {context?.TextoCitas}
                        </p>

                        {
                            context?.EnlaceCitas &&
                            <a href={context?.EnlaceCitas} className="primary-button-outline desktop-item big-text">Clic Aquí</a>
                        }
                    </div>

                    <div className="map-container">
                        <Splide className="oficinas-nav"
                            ref={thumbSlider}
                            renderControls={() => (
                                <div className="splide__arrows">
                                    <button type="button" className="splide__arrow splide__arrow--prev">
                                        <span className="icon-container">
                                            <span className="icon-arrow-left"></span>
                                        </span>
                                    </button>
                                    <button type="button" className="splide__arrow splide__arrow--next">
                                        <span className="icon-container">
                                            <span className="icon-arrow-right"></span>
                                        </span>
                                    </button>
                                </div>
                            )}>
                            {
                                context?.Oficinas?.map((oficina: HomeOficina) => {
                                    return (
                                        <SplideSlide key={`n-${oficina.Titulo}`}>
                                            <strong>{oficina.Titulo}</strong>
                                        </SplideSlide>
                                    );
                                })
                            }
                        </Splide>

                        <Splide id="oficinas-slider"
                            options={{
                                type: "loop",
                                cover: false,
                                autoplay: true,
                                arrows: false,
                                pagination: false
                            }}
                            ref={mainSlider}>
                            {
                                context?.Oficinas?.map((oficina: HomeOficina) => {
                                    return (
                                        <SplideSlide key={`o-${oficina.Titulo}`}>
                                            <img className="desktop-image" src={oficina.ImagenDesktop} alt="Mapa Ubicación Oficina" />
                                            <img className="mobile-image" src={oficina.ImagenMobile} alt="Mapa Ubicación Oficina" />

                                            <div className="direccion">
                                                <div className="section">
                                                    <strong>Dirección Y Teléfonos</strong>

                                                    <div className="detail">
                                                        <span className="icon-location"></span>
                                                        <p>{oficina.Direccion}</p>
                                                    </div>
                                                    <div className="detail">
                                                        <span className="icon-phone"></span>
                                                        <p>{oficina.Telefono}</p>
                                                    </div>
                                                </div>

                                                <div className="section">
                                                    <strong>Horarios de Servicio</strong>

                                                    <div className="detail">
                                                        <span className="icon-clock"></span>
                                                        <p>{oficina.Horario}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    );
                                })
                            }
                        </Splide>
                    </div>

                    <div className="mobile-item">
                        <p className="big-text">
                            {context?.TextoCitas}
                        </p>

                        {
                            context?.EnlaceCitas &&
                            <a href={context?.EnlaceCitas} className="primary-button-outline desktop-item big-text">Clic Aquí</a>
                        }
                    </div>
                </div>
            </section>

            <section>
                <EffectivenessGraph title={context?.TitulosGrafica?.Titulo}
                    subtitle={context?.TitulosGrafica?.Subtitulo}
                    entradas={context?.GraficaRentabilidad?.Entradas} showLink={true}></EffectivenessGraph>
            </section>

            <section id="satisfaction-notice">
                <div className="container">
                    <h2>{context?.TextoGarantia}</h2>
                </div>
            </section>
        </article>
    );
};