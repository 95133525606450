import { useEffect, useState } from "react";
import { BannerTop } from "../../../../components/BannerTop/BannerTop";
import { DocumentCard } from "../../../../components/Cards/DocumentCard/documentCard";
import { useBenefit } from "../../../../context/Affiliates/Benefits/Benefit/Benefit.context";
import { BannerTopContextProvider } from "../../../../context/Global/BannerTop/bannerTop.context";
import { Documento } from "../../../../context/Models/Document.models";
import "./Beneficio.css";

export const Beneficio = () => {
    const context = useBenefit();
    const baseBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Afiliados",
            url: "/Afiliados"
        },
        {
            title: "Beneficios",
            url: "/Afiliados/Beneficios"
        }
    ];
    const [pageBreadcrumbs, setBreadcrumbs] = useState(baseBreadcrumbs);

    useEffect(() => {
        if (context && context.Beneficio && context.Beneficio.Id)
            setBreadcrumbs([...baseBreadcrumbs, {
                title: context.Beneficio.Titulo,
                url: `/Afiliados/Beneficio?bId=${context.Beneficio.Id}`
            }]);
    }, [context]);

    return (
        <article id="beneficio">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} title={context.Beneficio?.Titulo} image={context?.Beneficio?.Imagen} />
            </BannerTopContextProvider>

            <section className="section bg-blanco">
                <div className="container">
                    <div className="column-content" dangerouslySetInnerHTML={{ __html: context?.Beneficio?.Contenido || "" }}>
                    </div>
                </div>
            </section>

            {
                (context.Documentos && context.Documentos.length > 0) &&

                <section className="section">
                    <div className="container">
                        <h3 className="text-center text-oscura">Documentos Legales Relacionados</h3>

                        <ul className="documentos">
                            {
                                context.Documentos?.map((documento: Documento) => {
                                    return (
                                        <li>
                                            <DocumentCard document={documento} key={documento.Nombre}></DocumentCard>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </section>
            }

            <section className="section">
                <div className="container">
                    <a href="#" className="primary-button request-link">Haz clic aquí para solicitar este beneficio</a>
                </div>
            </section>
        </article>
    );
};