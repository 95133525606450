import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";

import './DocumentosCorporativos.scoped.css';
import { useCorporateDocumentsPage } from "../../../context/AboutUs/CorporateDocuments/CorporateDocuments.context";
import { Documento } from "../../../context/Models/Document.models";
import { DocumentCard } from "../../../components/Cards/DocumentCard/documentCard";
import { RelatedInstitution } from "../../../context/AboutUs/CorporateDocuments/CorporateDocuments.models";

export const DocumentosCorporativos = () => {
    const context = useCorporateDocumentsPage();

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Nosotros",
            url: "/Nosotros"
        },
        {
            title: "Documentos Corporativos",
            url: "/Nosotros/DocumentosCorporativos"
        }
    ];

    return (
        <article id="documentos-corporativos">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <h3>Documentos</h3>

                <ul className="documentos">
                    {
                        context.Documents?.map((document: Documento) => {
                            return (
                                <li>
                                    <DocumentCard key={document.Nombre} document={document} icon="view"></DocumentCard>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>

            <div className="container">

                <h3>Instituciones</h3>

                <ul className="instituciones">
                    {
                        context.Institutions?.map((institution: RelatedInstitution) => {
                            return (
                                <li className="card" key={institution.Title}>
                                    <a href={institution.Url} target="_blank">
                                        <div className="img-container">
                                            <img src={institution.Image} alt={"Logo " + institution.Title} className="logo" />
                                        </div>
                                        <div className="card-info">
                                            <span className="text">{institution.Title}</span>
                                            <span className="icon-link"></span>
                                        </div>
                                    </a>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </article>
    );
};