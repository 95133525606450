import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { Get } from "../../../util/sharepointRequests";
import { Banner, BannerTop } from "./bannerTop.models";

type BannerTopProps = { children: ReactNode; };
const BannerTopContext = createContext<BannerTop | undefined>(undefined);

type Message =
    { type: 'banner'; value: Banner; };

const reducer = (state: BannerTop, message: Message) => {
    if (message.type == 'banner')
        return {
            ...state, Banner: message.value
        }
    else
        return { ...state };
};

const BannerTopContextProvider = ({ children }: BannerTopProps) => {

    const [state, dispatch] = useReducer(reducer, {} as BannerTop);
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState<string>("");

    useEffect(() => {
        let page = location.pathname;

        if (page.startsWith("/"))
            page = page.replace("/", "");
        if (page.endsWith("/"))
            page = page.substring(0, page.length - 1);

        setCurrentPage(page);
    }, [location]);

    useEffect(() => {
        fetchBanner();
    }, [currentPage]);

    async function fetchBanner() {
        const banner = await Get(`/_api/web/lists/GetByTitle('Banners Paginas')/items?$select=Activo,Title,File/ServerRelativeUrl&$expand=File&$filter=Activo eq 1 and Pagina eq '${currentPage}' or Pagina eq 'Default'`)
            .then(response => {
                let result: Banner[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Imagen: item.File.ServerRelativeUrl
                    });
                });

                return result[0];
            });

        if (banner)
            dispatch({ type: 'banner', value: banner });
    }

    return (
        <BannerTopContext.Provider value={state}>
            {children}
        </BannerTopContext.Provider>
    );

};

const useBanner = () => {
    const context = useContext(BannerTopContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useBanner, BannerTopContextProvider };