import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { RequestInfo } from "../Requests/Requests.models";
import { SignupRequestPage } from "./SignupRequest.models";
import { useLocation } from "react-router-dom";
import { Get, GetFieldOptions } from "../../../util/sharepointRequests";

type SignupRequestPageProps = { children: ReactNode; };
const SignupRequestContext = createContext<SignupRequestPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: string; }
    | { type: 'sectores'; value: Array<string>; }
    | { type: 'frecuencias'; value: Array<string>; }
    | { type: 'provincias'; value: Array<string>; };

const reducer = (state: SignupRequestPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'sectores':
            return {
                ...state, Sectores: message.value
            }
        case 'frecuencias':
            return {
                ...state, Frecuencias: message.value
            }
        case 'provincias':
            return {
                ...state, Provincias: message.value
            }
        default:
            return { ...state };
    }
};

const SignupRequestContextProvider = ({ children }: SignupRequestPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as SignupRequestPage);
    const location = useLocation();

    useEffect(() => {
        fetchIntro();
        fetchSectores();
        fetchFrecuencias();
        fetchProvincias();
    }, [location]);

    async function fetchIntro() {
        const data = await Get(`/Empleador/_api/web/lists/GetByTitle('Solicitudes')/items?$select=Id,Activo,Intro&$filter=Activo eq 1 and Enlace eq '${location.pathname}'`)
            .then(response => {
                let result: RequestInfo[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Intro: item.Intro
                    });
                });

                return result;
            });

        dispatch({ type: 'intro', value: data[0].Intro || "" });
    }

    async function fetchSectores() {
        const tipos = await GetFieldOptions("/Empleador/_api/web/lists/GetByTitle('Solicitudes Registro')", "Empresa_x0020_Sector")
            .then(response => {
                return response;
            });

        dispatch({ type: 'sectores', value: tipos });
    }

    async function fetchFrecuencias() {
        const tipos = await GetFieldOptions("/Empleador/_api/web/lists/GetByTitle('Solicitudes Registro')", "Contacto_x0020_Frecuencia")
            .then(response => {
                return response;
            });

        dispatch({ type: 'frecuencias', value: tipos });
    }

    async function fetchProvincias() {
        const tipos = await GetFieldOptions("/Empleador/_api/web/lists/GetByTitle('Solicitudes Registro')", "Contacto_x0020_Provincia")
            .then(response => {
                return response;
            });

        dispatch({ type: 'provincias', value: tipos });
    }

    return (
        <SignupRequestContext.Provider value={state}>
            {children}
        </SignupRequestContext.Provider>
    );

};

const useSignupRequest = () => {
    const context = useContext(SignupRequestContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useSignupRequest, SignupRequestContextProvider };