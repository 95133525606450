import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { BenefitContextProvider } from "../../context/Affiliates/Benefits/Benefit/Benefit.context";
import { BenefitsContextProvider } from "../../context/Affiliates/Benefits/Benefits.context";
import { ContributionsCalculatorPageContextProvider } from "../../context/Affiliates/Contributions/Contributions.context";
import { AffiliateFaqContextProvider } from "../../context/Affiliates/Faq/Faq.context";
import { PensionCalculatorPageContextProvider } from "../../context/Affiliates/Pension/Pension.context";
import { ServicesContextProvider } from "../../context/Affiliates/Services/Services.context";
import { CalculadoraAportes } from "./Aportes/CalculadoraAportes";
import { Beneficio } from "./Beneficios/Beneficio/Beneficio";
import { Beneficios } from "./Beneficios/Beneficios";
import { CalculadoraPensiones } from "./Pension/CalculadoraPensiones";
import { PreguntasFrecuentesAfiliados } from "./PreguntasFrecuentes/PreguntasFrecuentes";
import { Servicios } from "./Servicios/Servicios";

export const Afiliados = () => {

    return (
        <Routes>
            <Route path="" element={<Navigate replace to="Beneficios" />} />
            <Route path="Beneficios" element={
                <React.Suspense fallback={<>...</>}>
                    <BenefitsContextProvider>
                        < Beneficios />
                    </BenefitsContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Beneficio" element={
                <React.Suspense fallback={<>...</>}>
                    <BenefitContextProvider>
                        < Beneficio />
                    </BenefitContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Aportes" element={
                <React.Suspense fallback={<>...</>}>
                    <ContributionsCalculatorPageContextProvider>
                        < CalculadoraAportes />
                    </ContributionsCalculatorPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Pensiones" element={
                <React.Suspense fallback={<>...</>}>
                    <PensionCalculatorPageContextProvider>
                        < CalculadoraPensiones />
                    </PensionCalculatorPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="PreguntasFrecuentes" element={
                <React.Suspense fallback={<>...</>}>
                    <AffiliateFaqContextProvider>
                        < PreguntasFrecuentesAfiliados />
                    </AffiliateFaqContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Servicios" element={
                <React.Suspense fallback={<>...</>}>
                    <ServicesContextProvider>
                        < Servicios />
                    </ServicesContextProvider>
                </React.Suspense>
            }>
            </Route>
        </Routes>
    );
};