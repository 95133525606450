import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";

import './NormasReglamento.css';
import { useNormsPage } from "../../../context/AboutUs/Norms/Norms.context";

export const NormasReglamento = () => {
    const context = useNormsPage();

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Nosotros",
            url: "/Nosotros"
        },
        {
            title: "Familia Reservas",
            url: "/Nosotros/Familia"
        }
    ];

    return (
        <article id="normas-reglamentos">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <ul className="bullet-list norms">
                    {
                        context?.Normas?.map((norm) => {
                            return (
                                <li key={norm} className="norm"
                                    dangerouslySetInnerHTML={{ __html: norm }}></li>
                            );
                        })
                    }
                </ul>
            </div>
        </article>
    );
};