import { Link } from 'react-router-dom';
import { RequestInfo } from '../../../context/Employer/Requests/Requests.models';
import './requestCard.scoped.css';

export interface RequestCardProps {
    request: RequestInfo;
}

export function RequestCard(props: RequestCardProps) {
    return (
        <div className="solicitud">
            <div className="img-container">
                <img src={props?.request?.Imagen}
                    alt={"Imagen de " + props?.request?.Titulo} />
            </div>

            <div className="info">
                <h3>{props?.request?.Titulo}</h3>

                <p>{props?.request?.Descripcion}</p>

                <div className="link-container">
                    <Link to={props?.request?.Url || ""}>Leer más</Link>
                </div>
            </div>
        </div>
    );
}
