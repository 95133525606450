import DOMPurify from 'dompurify';
import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from '../../../util/sharepointRequests';
import { PageIntro } from "../../Models/page.models";
import { PensionCalculatorPage } from "./Pension.models";

type PensionCalculatorPageProps = { children: ReactNode; };
const PensionCalculatorPageContext = createContext<PensionCalculatorPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: PageIntro; };

const reducer = (state: PensionCalculatorPage, message: Message) => {
    if (message.type == 'intro')
        return {
            ...state, Intro: message.value
        }
    else
        return { ...state };
};

const PensionCalculatorPageContextProvider = ({ children }: PensionCalculatorPageProps) => {

    const [context, dispatch] = useReducer(reducer, {} as PensionCalculatorPage);

    useEffect(() => {
        fetchIntro();
    }, []);

    async function fetchIntro() {
        const intro = await Get("/Afiliados/_api/web/lists/GetByTitle('Calculadora Pensiones - Intro')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1")
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((items: any) => {
                    result.push({
                        Titulo: items.Title,
                        Resumen: DOMPurify.sanitize(items.Contenido, { USE_PROFILES: { html: true } })
                    });
                });

                return result[0];
            });

        if (intro)
            dispatch({ type: 'intro', value: intro });
    }

    return (
        <PensionCalculatorPageContext.Provider value={context}>
            {children}
        </PensionCalculatorPageContext.Provider>
    );

};

const usePensionCalculatorPage = () => {
    const context = useContext(PensionCalculatorPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { usePensionCalculatorPage, PensionCalculatorPageContextProvider };