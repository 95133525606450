import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get, GetFieldOptions } from "../../util/sharepointRequests";
import { ContactPage, Office } from "./Contact.models";

type ContactPageProps = { children: ReactNode; };
const ContactPageContext = createContext<ContactPage | undefined>(undefined);

type Message = { type: 'motivosVisita'; value: Array<string>; }
    | { type: 'oficinas'; value: Array<Office>; };

const reducer = (state: ContactPage, message: Message) => {
    switch (message.type) {
        case 'motivosVisita':
            return {
                ...state, MotivosVisita: message.value
            }
        case 'oficinas':
            return {
                ...state, Oficinas: message.value
            }
        default:
            return { ...state };
    }
};

const ContactPageContextProvider = ({ children }: ContactPageProps) => {

    const [contactPage, setContactPage] = useReducer(reducer, {} as ContactPage);

    useEffect(() => {
        fetchMotives();
        fetchOffices();
    }, []);

    async function fetchMotives() {
        const motives = await GetFieldOptions("/Contactanos/_api/web/lists/GetByTitle('Solicitudes Contacto')", "Motivo")
            .then(response => {
                return response;
            });

        setContactPage({ type: 'motivosVisita', value: motives });
    }

    async function fetchOffices() {
        const offices = await Get(`/Contactanos/_api/web/lists/GetByTitle('Oficinas')/items?$select=Orden,Activo,Title,Direccion,Telefono,Horario,Latitud,Longitud&$orderby=Orden&$filter=Activo eq 1`)
            .then(response => {
                let result: Office[] = [];

                response.forEach((item: any) => {
                    result.push({
                        title: item.Title,
                        address: item.Direccion,
                        phone: item.Telefono,
                        hours: item.Horario,
                        lat: item.Latitud,
                        lon: item.Longitud
                    });
                });

                return result;
            });

        setContactPage({ type: 'oficinas', value: offices });
    }

    return (
        <ContactPageContext.Provider value={contactPage}>
            {children}
        </ContactPageContext.Provider>
    );

};

const useContactPage = () => {
    const context = useContext(ContactPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useContactPage, ContactPageContextProvider };