import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { RequestInfo, RequestsPage } from "./Requests.models";

type RequestsPageProps = { children: ReactNode; };
const RequestsContext = createContext<RequestsPage | undefined>(undefined);

type Message =
    { type: 'solicitudes'; value: Array<RequestInfo>; };

const reducer = (state: RequestsPage, message: Message) => {
    if (message.type == 'solicitudes')
        return {
            ...state, Requests: message.value
        }
    else
        return { ...state };
};

const RequestsContextProvider = ({ children }: RequestsPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as RequestsPage);

    useEffect(() => {
        fetchRequests();
    }, []);

    async function fetchRequests() {
        const requests = await Get("/Empleador/_api/web/lists/GetByTitle('Solicitudes')/items?$select=Id,Orden,Activo,Title,Resumen,Enlace,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: RequestInfo[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Descripcion: item.Resumen,
                        Imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        Url: item.Enlace
                    });
                });

                return result;
            });

        dispatch({ type: 'solicitudes', value: requests });
    }

    return (
        <RequestsContext.Provider value={state}>
            {children}
        </RequestsContext.Provider>
    );

};

const useRequests = () => {
    const context = useContext(RequestsContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useRequests, RequestsContextProvider };