import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";

import './NotasPrensa.scoped.css';
import { usePressNotes } from "../../../context/Publications/PressNotes/PressNotes.context";
import { PressNoteCardContainer } from "../../../containers/PressNoteCardContainer/pressNoteCardContainer";

export const NotasPrensa = () => {
    const context = usePressNotes();

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Publicaciones",
            url: "/Publicaciones"
        },
        {
            title: "Notas de Prensa",
            url: "/Publicaciones/NotasPrensa"
        }
    ];

    return (
        <article id="press-notes">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <PressNoteCardContainer pressNoteCards={context?.Noticias} filter={true} pagination={true} />
            </div>
        </article>
    );
};