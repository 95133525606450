import { Link } from "react-router-dom";
import { Activity } from "../../../context/Publications/Activities/Activities.models";
import "./activityCard.scoped.css";

export interface ActivityCardProps {
    card: Activity;
}

export function ActivityCard(props: ActivityCardProps) {
    return (
        <div className="activity">
            <div className="img-container">
                <img src={props?.card?.Portada} alt={props?.card?.Titulo} />
            </div>

            <div className="info-container">
                <h3 className="title">{props?.card?.Titulo}</h3>

                <p className="summary">{props?.card?.Resumen}</p>

                <div className="bottom-info">
                    <ul className="tags">
                        <li>
                            <span className={"icon-activity-" + props?.card?.Categoria?.toLowerCase()}></span>
                            {props?.card?.Categoria}
                        </li>
                    </ul>

                    <div className="link-container">
                        <Link to={"/Publicaciones/Actividad?aId=" + props?.card?.Id}>Ver más</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
