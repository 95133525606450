import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { PageIntro } from "../../Models/page.models";
import { MemoriesPage, Memory } from "./Memories.models";

type MemoriesPageProps = { children: ReactNode; };
const MemoriesPageContext = createContext<MemoriesPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: PageIntro; }
    | { type: 'memorias'; value: Array<Memory>; };

const reducer = (state: MemoriesPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'memorias':
            return {
                ...state, Memorias: message.value
            }
        default:
            return { ...state };
    }
};

const MemoriesPageContextProvider = ({ children }: MemoriesPageProps) => {

    const [memoryPage, dispatch] = useReducer(reducer, {} as MemoriesPage);

    useEffect(() => {
        fetchIntro();
        fetchMemories();
    }, []);

    async function fetchIntro() {
        const intro = await Get(`/Publicaciones/_api/web/lists/GetByTitle('Memorias - Intro')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1`)
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Resumen: item.Contenido
                    });
                });

                return result[0];
            });

        if (intro)
            dispatch({ type: 'intro', value: intro });
    }

    async function fetchMemories() {
        const memories = await Get(`/Publicaciones/_api/web/lists/GetByTitle('Memorias - Memorias')/items?$select=Orden,Activo,Title,TipoDocumento,Modified,Ano,File/Length,File/Name,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1`)
            .then(response => {
                let result: Memory[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Tipo: item.TipoDocumento,
                        Ano: item.Ano,
                        Size: item.File.Length,
                        Url: item.File.ServerRelativeUrl,
                        Modified: new Date(item.Modified)
                    });
                });

                return result;
            });

        dispatch({ type: 'memorias', value: memories });
    }

    return (
        <MemoriesPageContext.Provider value={memoryPage}>
            {children}
        </MemoriesPageContext.Provider>
    );

};

const useMemoriesPage = () => {
    const context = useContext(MemoriesPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useMemoriesPage, MemoriesPageContextProvider };