import "./activitiesContainer.scoped.css";
import { useEffect, useState } from "react";
import { Activity } from "../../context/Publications/Activities/Activities.models";
import { ActivityCard } from "../../components/Cards/ActivityCard/activityCard";

export interface ActivitiesContainerProps {
    filter?: boolean;
    activities?: Activity[];
    pagination?: boolean;
    perPage?: number;
}

export function ActivitiesContainer(props: ActivitiesContainerProps) {
    const [activityType, setActivityType] = useState("Todos");
    const [activityTypes, setActivityTypes] = useState<Array<string>>([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSkip, setPageSkip] = useState(7);
    const [totalPages, setTotalPages] = useState(0);

    const [filteredCards, setFilteredCards] = useState<Array<Activity>>([]);
    const [pagedCards, setPagedCards] = useState<Array<Activity>>([]);

    useEffect(() => {
        if (props.perPage)
            setPageSkip(pageSkip);
    }, [props.perPage]);

    useEffect(() => {
        let activities: Array<string> = [];

        if (props.activities)
            props.activities.forEach((activity: Activity) => {
                if (!activities.includes(activity.Categoria))
                    activities.push(activity.Categoria);
            });

        setActivityTypes(activities);
    }, [props.activities]);

    //Filtering elements
    useEffect(() => {
        if (props.activities) {
            if (activityType == "Todos")
                setFilteredCards(props.activities);
            else
                setFilteredCards(props.activities.filter((card: Activity) => {
                    return card.Categoria == activityType;
                }));
        }
    }, [props.activities, activityType]);

    //Getting total pages
    useEffect(() => {
        setTotalPages(Math.ceil((filteredCards.length) / (pageSkip)));
    }, [filteredCards, pageSkip]);

    //Resetting current page when the amount of pages changes
    useEffect(() => {
        if (totalPages > 0)
            setCurrentPage(1);
    }, [totalPages]);

    //Setting current page
    useEffect(() => {
        if (filteredCards && filteredCards.length)
            setPagedCards(filteredCards.slice((currentPage - 1) * pageSkip, ((currentPage - 1) * pageSkip) + pageSkip));
    }, [filteredCards, currentPage]);

    return (
        <>
            {
                props?.filter &&
                <div className="filter">
                    <ul className="desktop-filter">
                        <li className="active">
                            <button type="button" onClick={() => { setActivityType("Todos") }}>Todos</button>
                        </li>
                        {
                            activityTypes?.map((type: string) => {
                                return (
                                    <li key={type}>
                                        <button type="button" onClick={() => { setActivityType(type) }}>
                                            <i className={"icon-activity-" + type.toLowerCase()}></i>
                                            {type}s
                                        </button>
                                    </li>
                                );
                            })
                        }
                    </ul>

                    <div className="mobile-filter form">
                        <div className="field">
                            <label htmlFor="tipo-actividad">Tipo de Actividad</label>
                            <select name="tipo-actividad"
                                id="tipo-actividad"
                                onChange={(e) => { setActivityType(e.target.value) }}>
                                <option value="Todos">Todas</option>
                                {
                                    activityTypes?.map((type: string) => {
                                        return (
                                            <option key={`o-${type}`} value={type}>{type}s</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
            }

            <div className="activities-list">

                {
                    pagedCards.map((activity: Activity) => {
                        return (
                            <ActivityCard
                                key={activity.Id}
                                card={activity} />
                        );
                    })
                }

                {
                    (props.pagination && totalPages > 1) &&
                    <div className="pagination-container">
                        <ul className="pagination">
                            <li className="prev">
                                <button type="button"
                                    disabled={currentPage == 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}>
                                    <i className="icon-circle-arrow-right"></i>
                                </button>
                            </li>
                            {
                                currentPage - 2 > 0 &&
                                <li>
                                    <button type="button"
                                        onClick={() => setCurrentPage(currentPage - 2)}>
                                        {currentPage - 2}
                                    </button>
                                </li>
                            }
                            {
                                currentPage - 1 > 0 &&
                                <li>
                                    <button type="button"
                                        onClick={() => setCurrentPage(currentPage - 1)}>
                                        {currentPage - 1}
                                    </button>
                                </li>
                            }
                            <li className="active">
                                <button type="button">{currentPage}</button>
                            </li>
                            {
                                currentPage + 1 <= totalPages &&
                                <li>
                                    <button type="button"
                                        onClick={() => setCurrentPage(currentPage + 1)}>
                                        {currentPage + 1}
                                    </button>
                                </li>
                            }
                            {
                                currentPage + 2 <= totalPages &&
                                <li>
                                    <button type="button"
                                        onClick={() => setCurrentPage(currentPage + 2)}>
                                        {currentPage + 2}
                                    </button>
                                </li>
                            }
                            <li className="next">
                                <button type="button"
                                    disabled={currentPage == totalPages}
                                    onClick={() => setCurrentPage(currentPage + 1)}>
                                    <i className="icon-circle-arrow-right"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        </>
    );
}
