import './Actividad.css';
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import { BannerTop } from '../../../components/BannerTop/BannerTop';
import { useActivity } from '../../../context/Publications/Activity/Activity.context';
import { GalleryContainer } from '../../../containers/GalleryContainer/galleryContainer';

export const Actividad = () => {
    const context = useActivity();

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Publicaciones",
            url: "/Publicaciones"
        },
        {
            title: "Actividades",
            url: "/Publicaciones/Actividades"
        }
    ];

    return (
        <article id="activity">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} slim={true} />
            </BannerTopContextProvider>

            <div className="activity-container">
                <div className="container">

                    <div className="details">
                        <div className="detail category">
                            <span className={"icon-activity-" + context.Activity?.Categoria.toLowerCase()}></span> {context.Activity?.Categoria}
                        </div>
                        <div className="detail published-date">
                            <span className="icon-calendar"></span> <strong>{context.Activity?.FechaPublicacion?.getDate()}/{(context.Activity?.FechaPublicacion?.getMonth() || 0) + 1}/{context.Activity?.FechaPublicacion?.getFullYear()}</strong>
                        </div>
                    </div>

                    <h3>{context.Activity?.Titulo}</h3>

                    <div className="activity-content" dangerouslySetInnerHTML={{ __html: context.Activity?.Contenido || "" }}></div>

                    {
                        (context.Images && context.Images?.length > 0) &&
                        <GalleryContainer Images={context.Images} />
                    }
                </div>
            </div>
        </article>
    );
};