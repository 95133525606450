import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";
import './Cifras.scoped.css';
import CountUp from "react-countup";
import { useNumbersPage } from "../../../context/AboutUs/Numbers/Numbers.context";
import { Cifra } from "../../../context/AboutUs/Numbers/Numbers.models";
import { useEffect, useState } from "react";
import { EffectivenessGraph } from "../../../components/Cifras/EffectivenessGraph/effectivenessGraph";

export const Cifras = () => {
    const context = useNumbersPage();
    const [numbers, setNumbers] = useState<{ [key: string]: Array<Cifra>; }>({});

    useEffect(() => {
        if (context.Cifras && context.Cifras.length > 0)
            setNumbers(
                context.Cifras.reduce<{ [key: string]: Array<Cifra>; }>((result: { [key: string]: Array<Cifra>; }, currentItem: Cifra) => {
                    if (!result[currentItem.Categoria])
                        result[currentItem.Categoria] = [];
                    result[currentItem.Categoria].push(currentItem);
                    return result;
                }, {})
            );
    }, [context.Cifras]);

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Nosotros",
            url: "/Nosotros"
        },
        {
            title: "Nuestras Cifras",
            url: "/Nosotros/Cifras"
        }
    ];

    return (
        <article id="cifras">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                {
                    Object.keys(numbers).map((key: string) => {
                        return (
                            <div className="fondo" key={key}>
                                <h2>{key}</h2>

                                <ul className="info-container">
                                    {
                                        numbers[key].map((number: Cifra) => {
                                            return (
                                                <li className="info" key={number.Titulo}>
                                                    <h3>
                                                        <CountUp
                                                            prefix={number.Prefijo}
                                                            end={number.Cantidad}
                                                            decimals={number.Decimales}
                                                            suffix={number.Sufijo}
                                                            separator="," />
                                                    </h3>
                                                    <p>{number.Titulo}</p>
                                                </li>);
                                        })
                                    }
                                </ul>
                            </div>
                        );
                    })
                }
            </div>

            <section>
                <EffectivenessGraph title={context?.TitulosGrafica?.Titulo}
                    subtitle={context?.TitulosGrafica?.Subtitulo}
                    entradas={context?.GraficaRentabilidad?.Entradas}></EffectivenessGraph>
            </section>
        </article>
    );
};