import axios from "axios";
import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { HeaderLink, HeaderPage } from "./header.models";

declare const window: any;

type HeaderPageProps = { children: ReactNode; };
const HeaderPageContext = createContext<HeaderPage | undefined>(undefined);

type Message = { type: 'navigation'; value: Array<HeaderLink>; };

const reducer = (state: HeaderPage, message: Message) => {
    if (message.type == 'navigation')
        return {
            ...state, links: message.value
        }
    else
        return { ...state };
};

const HeaderPageContextProvider = ({ children }: HeaderPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as HeaderPage);

    useEffect(() => {
        fetchNavigation();
    }, []);

    async function fetchNavigation() {
        try {
            const headerTermsetInput = document.querySelector<HTMLInputElement>("#header-termset-id");
            const headerTermsetName = document.querySelector<HTMLInputElement>("#header-termset-name");
            const headerTermsetGroup = document.querySelector<HTMLInputElement>("#header-termset-group");
            const headerTermsetSet = document.querySelector<HTMLInputElement>("#header-termset-set");

            if (headerTermsetInput && headerTermsetInput.value) {
                const digest = document.querySelector<HTMLInputElement>("#__REQUESTDIGEST");

                if (digest && digest.value)
                    return await axios.post("/_layouts/15/BRRD.AFP.SitePublico/TermStoreController.aspx/GetTerms",
                        {
                            request: {
                                Name: headerTermsetName?.value,
                                Group: headerTermsetGroup?.value,
                                Set: headerTermsetSet?.value
                            }
                        },
                        {
                            headers: {
                                "Accept": "application/json;odata=verbose",
                                "Content-Type": "application/json;odata=verbose",
                            }
                        }
                    ).then(response => {
                        let result: HeaderLink[] = [];

                        if (response.status == 200 && response.data && response.data.d && response.data.d)
                            result = parseTerms(response.data.d);
                        dispatch({ type: "navigation", value: result });
                    });
                else
                    return setTimeout(fetchNavigation, 100);
            }

        } catch (error) {
            console.log(error);
        }
    }

    function parseTerms(terms: any) {
        let result: Array<HeaderLink> = [];

        terms.forEach((term: any) => {
            result.push({
                title: term.Title || "",
                link: (term.CustomProperties.hasOwnProperty("Enlace") ? term.CustomProperties.Enlace : null) || "" || term.URL,
                externo: term.CustomProperties.hasOwnProperty("Externo") || false,
                classes: term.CustomProperties.LinkClasses || "",
                children: parseTerms(term.Children)
            });
        });

        return result;
    }

    return (
        <HeaderPageContext.Provider value={state}>
            {children}
        </HeaderPageContext.Provider>
    );

};

const useHeaderPage = () => {
    const context = useContext(HeaderPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useHeaderPage, HeaderPageContextProvider };