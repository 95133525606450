import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get, GetTitles } from "../../../util/sharepointRequests";
import { PageIntro } from "../../Models/page.models";
import { AboutUsPage, Award, BoardIntro, BoardMember, DirectiveCommitteeMember, Identity, ManagerWords } from "./AboutUs.models";

type AboutUsPageProps = { children: ReactNode; };
const AboutUsPageContext = createContext<AboutUsPage | undefined>(undefined);

type Message = { type: 'intro'; value: PageIntro; }
    | { type: 'identity'; value: Array<Identity>; }
    | { type: 'managerWords'; value: ManagerWords; }
    | { type: 'boardIntro'; value: BoardIntro; }
    | { type: 'directiveCommittee'; value: Array<DirectiveCommitteeMember>; }
    | { type: 'boardMembers'; value: Array<BoardMember>; }
    | { type: 'investors'; value: Array<string>; }
    | { type: 'awards'; value: Array<Award>; };

const reducer = (state: AboutUsPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'identity':
            return {
                ...state, Identidad: message.value
            }
        case 'managerWords':
            return {
                ...state, PalabrasGerente: message.value
            }
        case 'boardIntro':
            return {
                ...state, ConsejoIntro: message.value
            }
        case 'directiveCommittee':
            return {
                ...state, MiembrosComiteDirectivo: message.value
            }
        case 'boardMembers':
            return {
                ...state, MiembrosConsejo: message.value
            }
        case 'investors':
            return {
                ...state, Accionistas: message.value
            }
        case 'awards':
            return {
                ...state, Premios: message.value
            }
        default:
            return { ...state };
    }
};

const AboutUsPageContextProvider = ({ children }: AboutUsPageProps) => {

    const [aboutUsPage, dispatch] = useReducer(reducer, {} as AboutUsPage);

    useEffect(() => {
        fetchIntro();
        fetchIdentity();
        fetchManagerWords();
        fetchBoardIntro();
        fetchDirectiveCommitteeMembers();
        fetchBoardMembers();
        fetchInvestors();
        fetchAwards();
    }, []);

    async function fetchIntro() {
        const intro = await Get(`/Nosotros/_api/web/lists/GetByTitle('Quienes Somos - Intro')/items?$select=Activo,Title,Contenido,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1`)
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Resumen: item.Contenido,
                        Imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : ""
                    });
                });

                return result[0];
            });

        if (intro)
            dispatch({ type: 'intro', value: intro });
    }
    async function fetchIdentity() {
        const identity = await Get(`/Nosotros/_api/web/lists/GetByTitle('Quienes Somos - Identidad')/items?$select=Activo,Title,Contenido,Orden,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1&$orderby=Orden`)
            .then(response => {
                let result: Identity[] = [];


                response.forEach((item: any) => {
                    result.push({
                        titulo: item.Title,
                        cuerpo: item.Contenido,
                        icono: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : ""
                    });
                });

                return result;
            });

        if (identity.length > 0)
            dispatch({ type: 'identity', value: identity });
    }
    async function fetchManagerWords() {
        const managerWords = await Get(`/Nosotros/_api/web/lists/GetByTitle('Quienes Somos - Palabras Gerente')/items?$select=Activo,Cuerpo,Nombre,Posicion,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1`)
            .then(response => {
                let result: ManagerWords[] = [];

                response.forEach((item: any) => {
                    result.push({
                        cuerpo: item.Cuerpo,
                        nombre: item.Nombre,
                        posicion: item.Posicion,
                        imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : ""
                    });
                });

                return result[0];
            });

        if (managerWords)
            dispatch({ type: 'managerWords', value: managerWords });
    }
    async function fetchBoardIntro() {
        const boardIntro = await Get(`/Nosotros/_api/web/lists/GetByTitle('Quienes Somos - Consejo Intro')/items?$select=Activo,Title,Cuerpo,Periodo&$filter=Activo eq 1`)
            .then(response => {
                let result: BoardIntro[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Resumen: item.Cuerpo,
                        periodo: item.Periodo
                    });
                });

                return result[0];
            });

        if (boardIntro)
            dispatch({ type: 'boardIntro', value: boardIntro });
    }
    async function fetchDirectiveCommitteeMembers() {
        const committeeMembers = await Get(`/Nosotros/_api/web/lists/GetByTitle('Quienes Somos - Comite Directivo')/items?$select=Activo,Title,Cargo,Orden,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1&$orderby=Orden`)
            .then(response => {
                let result: DirectiveCommitteeMember[] = [];

                response.forEach((item: any) => {
                    result.push({
                        nombre: item.Title,
                        cargo: item.Cargo,
                        imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : ""
                    });
                });

                return result;
            });

        if (committeeMembers.length > 0)
            dispatch({ type: 'directiveCommittee', value: committeeMembers });
    }
    async function fetchBoardMembers() {
        const boardMembers = await Get(`/Nosotros/_api/web/lists/GetByTitle('Quienes Somos - Consejo Miembros')/items?$select=Activo,Title,Posicion,Orden&$filter=Activo eq 1&$orderby=Orden`)
            .then(response => {
                let result: BoardMember[] = [];

                response.forEach((item: any) => {
                    result.push({
                        nombre: item.Title,
                        posicion: item.Posicion
                    });
                });

                return result;
            });

        if (boardMembers.length > 0)
            dispatch({ type: 'boardMembers', value: boardMembers });
    }
    async function fetchInvestors() {
        const investors = await GetTitles(`/Nosotros/_api/web/lists/GetByTitle('Quienes Somos - Accionistas')/items?$select=Activo,Title,Orden&$filter=Activo eq 1&$orderby=Orden`);

        if (investors.length > 0)
            dispatch({ type: 'investors', value: investors });
    }
    async function fetchAwards() {
        const awards = await Get(`/Nosotros/_api/web/lists/GetByTitle('Quienes Somos - Premios')/items?$select=Activo,Title,Categoria,Ano,Descripcion,Orden,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1&$orderby=Orden`)
            .then(response => {
                let result: Award[] = [];

                response.forEach((item: any) => {
                    result.push({
                        titulo: item.Title,
                        categoria: item.Categoria,
                        ano: item.Ano,
                        descripcion: item.Descripcion,
                        imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : ""
                    });
                });

                return result;
            });

        if (awards.length > 0)
            dispatch({ type: 'awards', value: awards });
    }

    return (
        <AboutUsPageContext.Provider value={aboutUsPage}>
            {children}
        </AboutUsPageContext.Provider>
    );

};

const useAboutUsPage = () => {
    const context = useContext(AboutUsPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useAboutUsPage, AboutUsPageContextProvider };