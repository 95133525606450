import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        scroll();
    }, []);

    useEffect(() => {
        scroll();
    }, [location]);

    const scroll = () => {
        const workspace = document.getElementById("s4-workspace");
        const hash = location.hash;
        let hashedElement;

        if (workspace) {
            if (hash) {
                hashedElement = document.querySelector(hash);
                if (hashedElement)
                    hashedElement.scrollIntoView({ behavior: "smooth" });
            }
            if (!hash || !hashedElement) {
                workspace.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        }
    }

    return null;
}