import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { PressNote } from "../publications.models";
import { PressNotesPage } from "./PressNotes.models";

type PressNotesPageProps = { children: ReactNode; };
const PressNotesContext = createContext<PressNotesPage | undefined>(undefined);

type Message =
    { type: 'noticias'; value: Array<PressNote>; };

const reducer = (state: PressNotesPage, message: Message) => {
    if (message.type == 'noticias')
        return {
            ...state, Noticias: message.value
        }
    else
        return { ...state };
};

const PressNotesContextProvider = ({ children }: PressNotesPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as PressNotesPage);

    useEffect(() => {
        fetchPressNotes();
    }, []);

    async function fetchPressNotes() {
        const pressNotes = await Get("/Publicaciones/_api/web/lists/GetByTitle('Notas de Prensa')/items?$select=Id,Orden,Activo,Title,Resumen,FechaPublicacion,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: PressNote[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Resumen: item.Resumen,
                        Imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        FechaPublicacion: new Date(item.FechaPublicacion)
                    });
                });

                return result;
            });

        dispatch({ type: 'noticias', value: pressNotes });
    }

    return (
        <PressNotesContext.Provider value={state}>
            {children}
        </PressNotesContext.Provider>
    );

};

const usePressNotes = () => {
    const context = useContext(PressNotesContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { usePressNotes, PressNotesContextProvider };