import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { PageIntro } from "../../Models/page.models";
import { FamilyPage, Filial } from "./Family.models";

type FamilyPageProps = { children: ReactNode; };
const FamilyPageContext = createContext<FamilyPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: PageIntro; }
    | { type: 'filiales'; value: Array<Filial>; };

const reducer = (state: FamilyPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'filiales':
            return {
                ...state, Filiales: message.value
            }
        default:
            return { ...state };
    }
};

const FamilyPageContextProvider = ({ children }: FamilyPageProps) => {

    const [familyPage, setFamilyPage] = useReducer(reducer, {} as FamilyPage);

    useEffect(() => {
        fetchIntro();
        fetchFiliales();
    }, []);

    async function fetchIntro() {
        const intro = await Get(`/Nosotros/_api/web/lists/GetByTitle('Familia - Intro')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1`)
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Resumen: item.Contenido
                    });
                });

                return result[0];
            });

        if (intro)
            setFamilyPage({ type: 'intro', value: intro });
    }

    async function fetchFiliales() {
        const filiales = await Get(`/Nosotros/_api/web/lists/GetByTitle('Familia - Filiales')/items?$select=Orden,Activo,Title,Enlace,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1`)
            .then(response => {
                let result: Filial[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Url: item.Enlace,
                        Imagen: item.AttachmentFiles && item.AttachmentFiles.results.length > 0 ? item.AttachmentFiles.results[0].ServerRelativeUrl : ""
                    });
                });

                return result;
            });

        setFamilyPage({ type: 'filiales', value: filiales });
    }

    return (
        <FamilyPageContext.Provider value={familyPage}>
            {children}
        </FamilyPageContext.Provider>
    );

};

const useFamilyPage = () => {
    const context = useContext(FamilyPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useFamilyPage, FamilyPageContextProvider };