import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { PageIntro } from "../../Models/page.models";
import { ActivitiesPage, Activity } from "./Activities.models";

type ActivitiesPageProps = { children: ReactNode; };
const ActivitiesContext = createContext<ActivitiesPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: PageIntro; }
    | { type: 'actividades'; value: Array<Activity>; };

const reducer = (state: ActivitiesPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'actividades':
            return {
                ...state, Activities: message.value
            }
        default:
            return { ...state };
    }
};

const ActivitiesContextProvider = ({ children }: ActivitiesPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as ActivitiesPage);

    useEffect(() => {
        fetchIntro();
        fetchActivities();
    }, []);

    async function fetchIntro() {
        const intro = await Get(`/Publicaciones/_api/web/lists/GetByTitle('Actividades - Intro')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1`)
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((items: any) => {
                    result.push({
                        Titulo: items.Title,
                        Resumen: items.Contenido
                    });
                });

                return result[0];
            });

        if (intro)
            dispatch({ type: 'intro', value: intro });
    }

    async function fetchActivities() {
        const activities = await Get("/Publicaciones/_api/web/lists/GetByTitle('Actividades')/items?$select=Id,Orden,Activo,Title,Resumen,Categoria,FechaPublicacion,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: Activity[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Resumen: item.Resumen,
                        Categoria: item.Categoria,
                        Portada: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        FechaPublicacion: new Date(item.FechaPublicacion)
                    });
                });

                return result;
            });

        dispatch({ type: 'actividades', value: activities });
    }

    return (
        <ActivitiesContext.Provider value={state}>
            {children}
        </ActivitiesContext.Provider>
    );

};

const useActivities = () => {
    const context = useContext(ActivitiesContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useActivities, ActivitiesContextProvider };