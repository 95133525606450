import { PressNote } from "../../context/Publications/publications.models";
import { PressNoteCard } from "../../components/PressNotes/PressNoteCard/pressNoteCard";
import "./pressNoteCardContainer.scoped.css";
import { useEffect, useState } from "react";

export interface PressNoteCardContainerProps {
    className?: string;
    pressNoteCards?: PressNote[];

    pagination?: boolean;
    perPage?: number;

    filter?: boolean;
}

export function PressNoteCardContainer(props: PressNoteCardContainerProps) {
    const [filterYears, setFilterYears] = useState(Array<string>());
    const [fileYear, setYearFilter] = useState("Todos");

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSkip, setPageSkip] = useState(7);
    const [totalPages, setTotalPages] = useState(0);

    const [filteredCards, setFilteredCards] = useState<Array<PressNote>>([]);
    const [pagedCards, setPagedCards] = useState<Array<PressNote>>([]);

    useEffect(() => {
        if (props.perPage)
            setPageSkip(pageSkip);
    }, [props.perPage]);

    //Getting all years
    useEffect(() => {
        let years: Array<string> = [];

        if (props.pressNoteCards && props.pressNoteCards.length > 0)
            props.pressNoteCards.forEach(pressNote => {
                if (pressNote.FechaPublicacion) {
                    const newYear = pressNote.FechaPublicacion.getFullYear().toString();
                    if (!years.includes(newYear))
                        years.push(newYear);
                }
            });

        const sortedYears = [...years].sort((a: string, b: string) => { return parseInt(b) - parseInt(a); });

        setFilterYears(sortedYears);
    }, [props.pressNoteCards]);

    //Filtering elements
    useEffect(() => {
        if (props.pressNoteCards) {
            if (fileYear == "Todos")
                setFilteredCards(props.pressNoteCards);
            else
                setFilteredCards(props.pressNoteCards.filter((card: PressNote) => {
                    return card.FechaPublicacion?.getFullYear().toString() == fileYear;
                }));
        }
    }, [props.pressNoteCards, fileYear]);

    //Getting total pages
    useEffect(() => {
        setTotalPages(Math.ceil((filteredCards.length) / (pageSkip)));
    }, [filteredCards, pageSkip]);

    //Resetting current page when the amount of pages changes
    useEffect(() => {
        if (totalPages > 0)
            setCurrentPage(1);
    }, [totalPages]);

    //Setting current page
    useEffect(() => {
        if (filteredCards && filteredCards.length)
            setPagedCards(filteredCards.slice((currentPage - 1) * pageSkip, ((currentPage - 1) * pageSkip) + pageSkip));
    }, [filteredCards, currentPage]);

    return (
        <>
            {
                props.filter &&
                <div className="filter">
                    <div className="datepicker-container">
                        <div className="datepicker">
                            <select id="year-field"
                                onChange={(e) => setYearFilter(e.target.value)}
                                value={fileYear} >
                                <option value="Todos" selected>Todos</option>
                                {
                                    filterYears.map((year: string) => {
                                        return (
                                            <option value={year} key={year}>{year}</option>
                                        );
                                    })
                                }
                            </select>
                            <label htmlFor="year-label">
                                <span className="icon-calendar"></span> Año
                                <span className="pl-1 selected-year">{fileYear}</span>
                            </label>
                        </div>
                    </div>
                </div>
            }

            <div className={"news-container" + (props.className ? props.className : "")}>
                {
                    pagedCards?.map((pressNote: PressNote, index: number) => {
                        return (
                            <PressNoteCard
                                key={pressNote.Id}
                                id={pressNote.Id}
                                titulo={pressNote.Titulo}
                                resumen={pressNote.Resumen}
                                imagen={pressNote.Imagen}
                                fechaPublicacion={pressNote.FechaPublicacion}
                                destacada={index == 0} ></PressNoteCard>
                        )
                    })
                }

                {
                    (props.pagination && totalPages > 1) &&
                    <div className="pagination-container">
                        <ul className="pagination">
                            <li className="prev">
                                <button type="button"
                                    disabled={currentPage == 1}
                                    onClick={() => setCurrentPage(currentPage - 1)}>
                                    <i className="icon-circle-arrow-right"></i>
                                </button>
                            </li>
                            {
                                currentPage - 2 > 0 &&
                                <li>
                                    <button type="button"
                                        onClick={() => setCurrentPage(currentPage - 2)}>
                                        {currentPage - 2}
                                    </button>
                                </li>
                            }
                            {
                                currentPage - 1 > 0 &&
                                <li>
                                    <button type="button"
                                        onClick={() => setCurrentPage(currentPage - 1)}>
                                        {currentPage - 1}
                                    </button>
                                </li>
                            }
                            <li className="active">
                                <button type="button">{currentPage}</button>
                            </li>
                            {
                                currentPage + 1 <= totalPages &&
                                <li>
                                    <button type="button"
                                        onClick={() => setCurrentPage(currentPage + 1)}>
                                        {currentPage + 1}
                                    </button>
                                </li>
                            }
                            {
                                currentPage + 2 <= totalPages &&
                                <li>
                                    <button type="button"
                                        onClick={() => setCurrentPage(currentPage + 2)}>
                                        {currentPage + 2}
                                    </button>
                                </li>
                            }
                            <li className="next">
                                <button type="button"
                                    disabled={currentPage == totalPages}
                                    onClick={() => setCurrentPage(currentPage + 1)}>
                                    <i className="icon-circle-arrow-right"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        </>
    );
}
