import { useForm } from 'react-hook-form';
import { BannerTop } from '../../../components/BannerTop/BannerTop';
import { useVisitRequest } from '../../../context/Employer/VisitRequest/VisitRequest.context';
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import { Post } from '../../../util/sharepointRequests';
import './SolicitudVisita.css';

export const SolicitudVisita = () => {
    const context = useVisitRequest();
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (data: any) => {
        Post("/Empleador/_api/web/lists/GetByTitle('Solicitudes Visita')/items", data, "SP.Data.Solicitudes_x0020_VisitaListItem")
            .then(_response => {
                reset();
            });
    };

    const pageBreadcrumbs = [{
        title: "AFP Reservas",
        url: "/"
    },
    {
        title: "Empleador",
        url: "/Empleador"
    },
    {
        title: "Solicitudes",
        url: "/Empleador/Solicitudes"
    },
    {
        title: "Visita de Promotor",
        url: "/Empleador/SolicitudVisita"
    }];

    return (
        <section id="solicitud">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="info" dangerouslySetInnerHTML={{ __html: context.Intro || "" }}></div>

                <div className="solicitud-form">
                    <fieldset>
                        <div className="field">
                            <label htmlFor="nombre-empresa">Nombre de la Empresa*</label>
                            <input type="text"
                                id="nombre-empresa"
                                placeholder="Nombre de la Empresa"
                                {...register("Empresa_x0020_Nombre", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="direccion-empresa">Dirección</label>
                            <input type="text"
                                id="direccion-empresa"
                                placeholder="Dirección"
                                {...register("Empresa_x0020_Direccion", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="zona-empresa">Zona*</label>
                            <select
                                id="zona-empresa"
                                {...register("Empresa_x0020_Zona", { required: true, })}>
                                <option value="null">Zona</option>
                                {
                                    context?.Zonas?.map((zona: string) => {
                                        return (
                                            <option key={zona} value={zona}>{zona}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                    </fieldset>
                    <fieldset>
                        <div className="field">
                            <label htmlFor="contacto-empresa">Persona de Contacto*</label>
                            <input type="text"
                                id="contacto-empresa"
                                placeholder="Nombre Apellido"
                                {...register("Contacto_x0020_Nombre", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="telefono-empresa">Número de Teléfono*</label>
                            <input type="text"
                                id="telefono-empresa"
                                placeholder="(000)-000-0000"
                                {...register("Empresa_x0020_Telefono", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="tipo-solicitud">Tipo de Solicitud*</label>
                            <select id="tipo-solicitud"
                                {...register("Tipo_x0020_Solicitud", { required: true, })}>
                                <option value="null">Solicitud</option>
                                {
                                    context?.Solicitudes?.map((solicitud: string) => {
                                        return (
                                            <option key={solicitud} value={solicitud}>{solicitud}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div className="field">
                            <label htmlFor="notas-empresa">Notas</label>
                            <textarea id="notas-empresa"
                                cols={30} rows={1}
                                placeholder="Notas"
                                {...register("Notas", { required: true, })}></textarea>
                        </div>
                    </fieldset>

                    <div className="submit-container">
                        <button type="button" className="primary-button" onClick={handleSubmit(onSubmit)}>Enviar</button>
                    </div>
                </div>
            </div>
        </section>
    );
};