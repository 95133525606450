import { Link } from "react-router-dom";
import "./pressNoteCard.scoped.css";

export interface PressNoteCardProps {
    id: number;
    className?: string;
    titulo?: string;
    resumen?: string;
    contenido?: string;
    imagen?: string;
    destacada?: boolean;
    fechaPublicacion?: Date;
}

export function PressNoteCard(props: PressNoteCardProps) {
    return (
        <div className={"new" + (props.className ? " " + props.className : "") + (props.destacada ? " highlight" : "")}>

            <div className="img-container">
                <img src={props.imagen} alt={"Imagen de " + props.titulo} />
            </div>

            <div className="news-content">
                <h4 className="news-title">{props.titulo}</h4>

                <p className="news-summary">{props.resumen}</p>

                <div className="news-info">
                    <span className="date">{props.fechaPublicacion?.getDate()}/{(props.fechaPublicacion?.getMonth() || 0) + 1}/{props.fechaPublicacion?.getFullYear()}</span>

                    <div className="link-container">
                        <Link to={"/Publicaciones/NotaPrensa?pId=" + props.id}>Leer más</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
