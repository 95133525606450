import { QuestionCard } from "../../components/Cards/QuestionCard/questionCard";

export interface QuestionsContainerProps {
    className?: string;
    questions?: Array<Question>;
}

export interface Question {
    title: string;
    content: string;
}

export function QuestionsContainer(props: QuestionsContainerProps) {
    return (
        <div className={"q-list" + (props.className ? " " + props.className : "")}>
            {
                props?.questions?.map((pressNote: Question, index: number) => {
                    return (
                        <QuestionCard
                            key={pressNote.title}
                            index={index}
                            title={pressNote.title}
                            content={pressNote.content} />
                    );
                })
            }
        </div>
    );
}
