import "./galleryContainer.css";
import { ActivityImage } from "../../context/Publications/Activity/Activity.models";
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import { useEffect } from "react";

export interface GalleryContainerProps {
    Images: Array<ActivityImage>;
}

export function GalleryContainer(props: GalleryContainerProps) {
    const lightbox = GLightbox();

    useEffect(() => {
        lightbox.reload();
    }, [props.Images]);

    return (
        <div className="gallery-container">
            {
                props.Images?.map((image: ActivityImage) => {
                    return (
                        <a className="glightbox gallery-card"
                            key={image.Title}
                            href={image.Url}>
                            <img src={image.Thumbnail} alt={image.Title} />
                        </a>
                    );
                })
            }
        </div>
    );
}
