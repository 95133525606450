import DOMPurify from 'dompurify';
import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from '../../../util/sharepointRequests';
import { PageIntro } from "../../Models/page.models";
import { Faq, FaqPage } from "./Faq.models";

type FaqEmployerPageProps = { children: ReactNode; };
const FaqEmployerContext = createContext<FaqPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: PageIntro; }
    | { type: 'questions'; value: Faq[]; };

const reducer = (state: FaqPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'questions':
            return {
                ...state, Questions: message.value
            }
        default:
            return { ...state };
    }
};

const EmployerFaqContextProvider = ({ children }: FaqEmployerPageProps) => {

    const [faq, setFaq] = useReducer(reducer, {} as FaqPage);

    useEffect(() => {
        fetchIntro();
        fetchFaq();
    }, []);

    async function fetchIntro() {
        const intro = await Get("/Empleador/_api/web/lists/GetByTitle('Preguntas Frecuentes - Intro')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1")
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Resumen: item.Contenido
                    });
                });

                return result[0];
            });

        if (intro)
            setFaq({ type: 'intro', value: intro });
    }

    async function fetchFaq() {
        const questions = await Get("/Empleador/_api/web/lists/GetByTitle('Preguntas Frecuentes')/items?$select=Orden,Activo,Id,Title,Contenido&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: Faq[] = [];

                response.forEach((item: any) => {
                    result.push({
                        id: item.Id,
                        title: item.Title,
                        content: DOMPurify.sanitize(item.Contenido, { USE_PROFILES: { html: true } })
                    });
                });

                return result;
            });

        setFaq({ type: 'questions', value: questions });
    }

    return (
        <FaqEmployerContext.Provider value={faq}>
            {children}
        </FaqEmployerContext.Provider>
    );

};

const useFaqEmployer = () => {
    const context = useContext(FaqEmployerContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useFaqEmployer, EmployerFaqContextProvider };