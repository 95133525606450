import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { EmployerFaqContextProvider } from "../../context/Employer/Faq/Faq.context";
import { RequestsContextProvider } from "../../context/Employer/Requests/Requests.context";
import { SignupRequestContextProvider } from "../../context/Employer/SignupRequest/SignupRequest.context";
import { VisitRequestContextProvider } from "../../context/Employer/VisitRequest/VisitRequest.context";
import { PreguntasFrecuentesEmpleador } from "./PreguntasFrecuentes/PreguntasFrecuentes";
import { Solicitudes } from "./Solicitudes/Solicitudes";
import { SolicitudRegistro } from "./SolicitudRegistro/SolicitudRegistro";
import { SolicitudVisita } from "./SolicitudVisita/SolicitudVisita";

export const Empleador = () => {

    return (
        <Routes>
            <Route path="" element={<Navigate replace to="Solicitudes" />} />
            <Route path="PreguntasFrecuentes" element={
                <React.Suspense fallback={<>...</>}>
                    <EmployerFaqContextProvider>
                        < PreguntasFrecuentesEmpleador />
                    </EmployerFaqContextProvider>
                </React.Suspense>
            }>
            </Route>

            <Route path="Solicitudes" element={
                <React.Suspense fallback={<>...</>}>
                    <RequestsContextProvider>
                        < Solicitudes />
                    </RequestsContextProvider>
                </React.Suspense>
            }>
            </Route>

            <Route path="SolicitudVisita" element={
                <React.Suspense fallback={<>...</>}>
                    <VisitRequestContextProvider>
                        < SolicitudVisita />
                    </VisitRequestContextProvider>
                </React.Suspense>
            }>
            </Route>

            <Route path="SolicitudRegistro" element={
                <React.Suspense fallback={<>...</>}>
                    <SignupRequestContextProvider>
                        < SolicitudRegistro />
                    </SignupRequestContextProvider>
                </React.Suspense>
            }>
            </Route>
        </Routes>
    );
};