import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useBanner } from "../../context/Global/BannerTop/bannerTop.context";
import { PageHeaders } from "../PageHeaders/PageHeaders";
import "./bannerTop.scoped.css";

export interface BannerTopProps {
    className?: string;
    title?: string;
    pageTitle?: string;
    image?: string;
    breadcrumbs?: Array<Breadcrumb>;
    slim?: boolean;
}

export interface Breadcrumb {
    title: string;
    url: string;
}

export function BannerTop(props: BannerTopProps) {
    const context = useBanner();
    const [image, setImage] = useState("");
    const [title, setTitle] = useState("");

    useEffect(() => {
        if (props.image)
            setImage(props.image);
        else if (context?.Banner?.Imagen)
            setImage(context?.Banner?.Imagen);

        if (props.title)
            setTitle(props.title);
        else if (context?.Banner?.Titulo)
            setTitle(context?.Banner?.Titulo);
    }, [props, context.Banner]);

    return (
        <>
            <PageHeaders title={props.pageTitle || title} />

            <section id="page-header" className={(props.slim ? "slim " : "")}>
                <div className="container">
                    <ul className="breadcrumbs">
                        {
                            props?.breadcrumbs?.map((page: Breadcrumb, index: number) => {
                                return (
                                    <li key={page.title}>
                                        <Link to={page.url}>{page.title}</Link>
                                        {
                                            (props.breadcrumbs && index < props.breadcrumbs.length - 1) &&

                                            <span className="icon-arrow-right"></span>
                                        }
                                    </li>
                                );
                            })
                        }
                    </ul>

                    <h2>{title}</h2>
                </div>

                <div className="background-container">
                    <img src={image} alt="Fondo del banner" />
                    <div className="overlay"></div>
                </div>
            </section>
        </>
    );
}
