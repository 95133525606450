import { BannerTop } from '../../../components/BannerTop/BannerTop';
import { DynamicImage } from '../../../components/Images/DynamicImage/dynamicImage';
import { useServices } from '../../../context/Affiliates/Services/Services.context';
import { Service } from '../../../context/Affiliates/Services/Services.models';
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import './Servicios.css';

export const Servicios = () => {
    const context = useServices();
    const pageBreadcrumbs = [{
        title: "AFP Reservas",
        url: "/"
    },
    {
        title: "Afiliados",
        url: "/Afiliados"
    },
    {
        title: "Servicios",
        url: "/Afiliados/Servicios"
    }];

    return (
        <article id="services">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="services-list">
                    {
                        context.Services?.map((service: Service) => {
                            return (
                                <a href={service.enlace ? service.enlace : "#"} className="service"
                                    key={service.title}>
                                    <DynamicImage src={service.icon} className="icon" />

                                    <h5>{service.title}</h5>

                                    <p>{service.content}</p>
                                </a>
                            );
                        })
                    }
                </div>
            </div>
        </article>
    );
};