import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { PageIntro } from "../../Models/page.models";
import { Benefit } from "./Benefit/Benefit.models";
import { BenefitsPage } from "./Benefits.models";

type BenefitsPageProps = { children: ReactNode; };
const BenefitsContext = createContext<BenefitsPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: PageIntro; }
    | { type: 'benefits'; value: Benefit[]; };

const reducer = (state: BenefitsPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'benefits':
            return {
                ...state, Benefits: message.value
            }
        default:
            return { ...state };
    }
};

const BenefitsContextProvider = ({ children }: BenefitsPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as BenefitsPage);

    useEffect(() => {
        fetchIntro();
        fetchBenefits();
    }, []);

    async function fetchIntro() {
        const intro = await Get("/Afiliados/Beneficios/_api/web/lists/GetByTitle('Intro')/items?$select=Activo,Title,Resumen,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1")
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Resumen: item.Resumen,
                        Imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : "",
                    });
                });

                return result[0];
            });

        if (intro)
            dispatch({ type: 'intro', value: intro });
    }

    async function fetchBenefits() {
        const benefits = await Get("/Afiliados/Beneficios/_api/web/lists/GetByTitle('Beneficios')/items?$select=Orden,Activo,Id,Title,Resumen,TextoEnlace,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: Benefit[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Resumen: item.Resumen,
                        Imagen: item.Attachments ? item.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        UrlText: item.TextoEnlace
                    });
                });

                return result;
            });

        dispatch({ type: 'benefits', value: benefits });
    }

    return (
        <BenefitsContext.Provider value={state}>
            {children}
        </BenefitsContext.Provider>
    );

};

const useBenefits = () => {
    const context = useContext(BenefitsContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useBenefits, BenefitsContextProvider };