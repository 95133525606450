import { useEffect, useState } from "react";
import { BannerTop } from "../../components/BannerTop/BannerTop";
import { useContactPage } from "../../context/Contact/Contact.context";
import { Office } from "../../context/Contact/Contact.models";
import { BannerTopContextProvider } from "../../context/Global/BannerTop/bannerTop.context";
import GoogleMapReact from 'google-map-react';
import { useForm } from "react-hook-form";

import './Contacto.css';
import { Post } from "../../util/sharepointRequests";

export const Contacto = () => {
    const context = useContactPage();
    const [currentOffice, setCurrentOffice] = useState<Office>();

    useEffect(() => {
        if (context.Oficinas && context.Oficinas.length > 0)
            setCurrentOffice(context.Oficinas[0]);
    }, [context.Oficinas]);

    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (data: any) => {
        Post("/Contactanos/_api/web/lists/GetByTitle('Solicitudes Contacto')/items", data, "SP.Data.Solicitudes_x0020_ContactoListItem")
            .then(_response => {
                reset();
            });
    };

    const MapMarker = (props: { lat: number; lng: number; }) =>
        <div className="marker-container">
            <img src="/ReactApp/images/map-marker.svg" alt="" />
        </div>;

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Contáctanos",
            url: "/Contacto"
        }
    ];

    return (
        <div id="contacts">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="contact-form">
                    <div className="container">
                        <h3>¿Necesitas asistencia?</h3>

                        <h4>Comunícate con nosotros</h4>

                        <div className="form">
                            <div className="field">
                                <label htmlFor="nombre">Nombre</label>
                                <input id="nombre"
                                    type="text"
                                    placeholder="Nombre"
                                    {...register("Title", { required: true })} />
                            </div>

                            <div className="field">
                                <label htmlFor="email">E-Mail</label>
                                <input id="email"
                                    type="text"
                                    placeholder="ejemplo@correo.com"
                                    {...register("Correo", { required: true })} />
                            </div>

                            <div className="field">
                                <label htmlFor="telefono">Teléfono</label>
                                <input id="telefono"
                                    type="text"
                                    placeholder="(000) 000 - 0000"
                                    {...register("Telefono", { required: true })} />
                            </div>

                            <div className="field">
                                <label htmlFor="motivo">Motivo</label>
                                <select id="motivo"
                                    {...register("Motivo", { required: true })}>
                                    <option value="null">Motivo</option>
                                    {
                                        context?.MotivosVisita?.map((motivo: string) => {
                                            return (
                                                <option value={motivo} key={motivo}>{motivo}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>

                            <div className="field">
                                <label htmlFor="mensaje">Mensaje</label>
                                <textarea id="mensaje"
                                    cols={30} rows={4}
                                    placeholder="Mensaje"
                                    {...register("Mensaje", { required: true })}></textarea>
                            </div>

                            <div className="submit-container">
                                <button type="button" className="primary-button"
                                    onClick={handleSubmit(onSubmit)}>Enviar</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="maps-container">
                    <div className="container">
                        <div className="offices-container">
                            <ul className="office-selector">
                                {
                                    context?.Oficinas?.map((office: Office) => {
                                        return (
                                            <li className={"office" + (currentOffice?.title == office.title ? " active" : "")} key={office.title}>
                                                <button type="button"
                                                    onClick={() => { setCurrentOffice(office) }}>
                                                    {office.title}
                                                </button>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>

                        <div className="map-container">
                            {
                                (currentOffice) &&
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyCK2DEx1ozVOjHRmUiQ1MK25Pbv7ciSz5g" }}
                                    defaultCenter={{ lat: 0, lng: 0 }}
                                    center={{
                                        lat: currentOffice?.lat,
                                        lng: currentOffice?.lon
                                    }}
                                    zoom={15}
                                >
                                    <MapMarker
                                        lat={currentOffice?.lat}
                                        lng={currentOffice?.lon} />

                                </GoogleMapReact>
                            }
                        </div>

                        <div className="address">
                            <h5>Dirección y Teléfono</h5>

                            <div className="address-section">
                                <span className="icon-container">
                                    <i className="icon-location"></i>
                                </span>
                                <p>{currentOffice?.address}</p>
                            </div>

                            <div className="address-section">
                                <span className="icon-container">
                                    <i className="icon-phone"></i>
                                </span>
                                <p>{currentOffice?.phone}</p>
                            </div>

                            <h5>Horarios de Servicio</h5>

                            <div className="address-section">
                                <span className="icon-container">
                                    <i className="icon-clock"></i>
                                </span>
                                <p>{currentOffice?.hours}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};