import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useHeaderPage } from "../../context/Global/Header/header.context";
import { HeaderLink } from "../../context/Global/Header/header.models";
import { StaticImage } from "../Images/StaticImage/staticImage";
import "./header.css";

export function Header() {
    const context = useHeaderPage();

    const [showMenu, setShowMenu] = useState(false);
    const [activeMenu, setActiveMenu] = useState("");


    return (
        <section id="header">
            <div className="container">
                <Link className="logo-container" to="/">
                    <StaticImage src="/images/logo.svg" alt="Logo AFP Reservas" className="logo" />
                </Link>

                <nav className="main-menu">
                    <ul className="desktop-menu">
                        {
                            context?.links?.map((item: HeaderLink) => {
                                return (
                                    <li className={"menu-item" + (item.children && item.children.length > 0 ? " has-child" : "")}
                                        key={item.title}>
                                        {
                                            item.externo &&
                                            <a href={item.link} target="_blank"
                                                className={(item.classes ? " " + item.classes : "")}>{item.title}</a>
                                        }
                                        {
                                            !item.externo &&
                                            <NavLink to={item.link}
                                                className={({ isActive }) => (isActive ? ' active' : '') + (item.classes ? " " + item.classes : "")}>{item.title}</NavLink>
                                        }

                                        {
                                            (item.children && item.children.length > 0) &&
                                            <span className="icon-container">
                                                <span className="icon-menu-dropdown"></span>
                                            </span>
                                        }

                                        {
                                            (item.children && item.children.length > 0) &&
                                            <ul className="sub-menu">
                                                {
                                                    item.children.map((child: HeaderLink) => {
                                                        return (
                                                            <li className="sub-menu-item" key={child.title}>
                                                                {
                                                                    child.externo &&
                                                                    <a href={child.link} target="_blank">{child.title} <span className="icon-link"></span></a>
                                                                }
                                                                {
                                                                    !child.externo &&
                                                                    <Link to={child.link}>{child.title}</Link>
                                                                }
                                                            </li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        }
                                    </li>
                                );
                            })
                        }
                    </ul>

                    <div className="mobile-menu">
                        <button id="menu-toggle"
                            type="button"
                            onClick={() => { setShowMenu(!showMenu); }}>
                            <input type="checkbox" />
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>

                        <div className={"menu" + (showMenu ? " show" : "")}>
                            <ul>
                                {
                                    context?.links?.map((item: HeaderLink) => {
                                        return (
                                            <li className={"menu-item" + (activeMenu == item.title ? " expand" : "") + (item.children && item.children.length > 0 ? " has-child" : "")}
                                                key={item.title}>

                                                {
                                                    ((item.children && item.children.length < 1) && item.externo) &&
                                                    <a href={item.link} target="_blank"
                                                        className={(item.classes ? " " + item.classes : "")}>{item.title}</a>
                                                }
                                                {
                                                    ((item.children && item.children.length < 1) && !item.externo) &&
                                                    <NavLink to={item.link}
                                                        className={(item.classes ? " " + item.classes : "")}>{item.title}</NavLink>
                                                }

                                                {
                                                    (item.children && item.children.length > 0) &&
                                                    <button type="button"
                                                        onClick={() => { activeMenu == item.title ? setActiveMenu('') : setActiveMenu(item.title); }}>
                                                        {item.title}
                                                        <span className="icon-container">
                                                            <span className="icon-menu-dropdown"></span>
                                                        </span>
                                                    </button>
                                                }

                                                {
                                                    (item.children && item.children.length > 0) &&
                                                    <ul className="sub-menu">
                                                        {
                                                            item.children.map((child: HeaderLink) => {
                                                                return (
                                                                    <li className="sub-menu-item" key={child.title}>
                                                                        {
                                                                            child.externo &&
                                                                            <a href={child.link} target="_blank">{child.title} <span className="icon-link"></span></a>
                                                                        }
                                                                        {
                                                                            !child.externo &&
                                                                            <Link to={child.link}>{child.title}</Link>
                                                                        }
                                                                    </li>
                                                                );
                                                            })
                                                        }
                                                    </ul>
                                                }
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </section>
    );
}
