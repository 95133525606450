import { decode } from 'html-entities';
import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from '../../util/sharepointRequests';
import { GraphTitles } from "../AboutUs/Numbers/Numbers.models";
import { PressNote } from "../Publications/publications.models";
import { EnlaceDescargaApp, GraficaRentabilidad, HomePage, HomeBanner, HomeOficina } from "./home.models";

type HomePageProps = { children: ReactNode; };
const HomeContext = createContext<HomePage | undefined>(undefined);

type Message =
    { type: 'banner'; value: HomeBanner[]; }
    | { type: 'barraAfiliacion'; value: string; }
    | { type: 'enlaceAfiliacion'; value: string; }
    | { type: 'notasPrensa'; value: PressNote[]; }
    | { type: 'imagenApp'; value: string; }
    | { type: 'textoApp'; value: string; }
    | { type: 'enlaceGoogle'; value: EnlaceDescargaApp; }
    | { type: 'enlaceApple'; value: EnlaceDescargaApp; }
    | { type: 'codigoQr'; value: EnlaceDescargaApp; }
    | { type: 'textoCitas'; value: string; }
    | { type: 'enlaceCitas'; value: string; }
    | { type: 'oficinas'; value: HomeOficina[]; }
    | { type: 'titulosGrafica'; value: GraphTitles; }
    | { type: 'graficaRentabilidad'; value: GraficaRentabilidad; }
    | { type: 'textoGarantia'; value: string; };

const reducer = (state: HomePage, message: Message) => {
    switch (message.type) {
        case 'banner':
            return {
                ...state, Banner: message.value
            }
        case 'barraAfiliacion':
            return {
                ...state, BarraAfiliacion: message.value
            }
        case 'enlaceAfiliacion':
            return {
                ...state, EnlaceAfiliacion: message.value
            }
        case 'notasPrensa':
            return {
                ...state, NotasPrensa: message.value
            }
        case 'imagenApp':
            return {
                ...state, ImagenApp: message.value
            }
        case 'textoApp':
            return {
                ...state, TextoApp: message.value
            }
        case 'enlaceGoogle':
            return {
                ...state, EnlaceGoogle: message.value
            }
        case 'enlaceApple':
            return {
                ...state, EnlaceApple: message.value
            }
        case 'codigoQr':
            return {
                ...state, CodigoQR: message.value
            }
        case 'textoCitas':
            return {
                ...state, TextoCitas: message.value
            }
        case 'enlaceCitas':
            return {
                ...state, EnlaceCitas: message.value
            }
        case 'oficinas':
            return {
                ...state, Oficinas: message.value
            }
        case 'graficaRentabilidad':
            return {
                ...state, GraficaRentabilidad: message.value
            }
        case 'titulosGrafica':
            return {
                ...state, TitulosGrafica: message.value
            }
        case 'textoGarantia':
            return {
                ...state, TextoGarantia: message.value
            }
        default:
            return { ...state };
    }
};

const HomeContextProvider = ({ children }: HomePageProps) => {

    const [context, dispatch] = useReducer(reducer, {} as HomePage);

    useEffect(() => {
        fetchTexts();
        fetchBanners();
        fetchPressNotes();
        fetchOffices();
        fetchCifrasIntro();
        fetchGraph();
    }, []);

    async function fetchTexts() {
        await Get("/_api/web/lists/GetByTitle('Informacion General')/items?$select=Activo,Title,Campo,Contenido,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1")
            .then(response => {
                response.forEach((value: any) => {
                    const valueType = getValueType(value.Campo);

                    switch (valueType) {
                        //Contenidos
                        case 'barraAfiliacion':
                        case 'textoApp':
                        case 'textoCitas':
                        case 'textoGarantia':
                            dispatch({
                                type: valueType,
                                value: value.Contenido
                            });
                            break;
                        case 'enlaceAfiliacion':
                        case 'enlaceCitas':
                            dispatch({
                                type: valueType,
                                value: decode(value.Contenido)
                            });
                            break;
                        case 'imagenApp':
                            dispatch({
                                type: valueType,
                                value: value.Attachments ? value.AttachmentFiles.results[0].ServerRelativeUrl : ""
                            });
                            break;
                        case 'enlaceGoogle':
                        case 'enlaceApple':
                        case 'codigoQr':
                            dispatch({
                                type: valueType,
                                value: {
                                    Titulo: value.Title,
                                    Url: decode(value.Contenido),
                                    Imagen: value.Attachments ? value.AttachmentFiles.results[0].ServerRelativeUrl : ""
                                }
                            });
                            break;
                    }
                });
            });
    }

    const getValueType = (campo: string) => {
        let result = "";

        let words = campo.split(" ");

        words.forEach((word: string, index: number) => {
            if (index == 0)
                result += word.toLowerCase();
            else
                result += word[0].toUpperCase() + word.substring(1).toLowerCase();
        });

        return result;
    };

    async function fetchBanners() {
        const banner = await Get("/_api/web/lists/GetByTitle('Banner Principal')/items?$select=Id,Orden,Activo,Title,Contenido,Enlace,TextoEnlace,MobileId,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: HomeBanner[] = [];
                let mobileIds: Array<number> = [];

                response.forEach((slide: any) => {
                    let mobileSlide: any;

                    if (slide.MobileId) {
                        mobileSlide = response.find((s: any) => s.Id == slide.MobileId);

                        if (mobileSlide && mobileSlide.Id) {
                            mobileIds.push(mobileSlide.Id);
                        }
                    }

                    result.push({
                        Id: slide.Id,
                        Titulo: slide.Title ? slide.Title : slide.File.Name,
                        Contenido: slide.Contenido,
                        Imagen: slide.File.ServerRelativeUrl,
                        ImagenMobile: mobileSlide ? mobileSlide.File.ServerRelativeUrl : slide.File.ServerRelativeUrl,
                        Url: slide.Enlace,
                        TextoEnlace: slide.TextoEnlace
                    });
                });

                return result.filter((slide: HomeBanner) => mobileIds.indexOf(slide.Id) < 0);
            });

        dispatch({ type: 'banner', value: banner });
    }

    async function fetchPressNotes() {
        const pressNotes = await Get("/Publicaciones/_api/web/lists/GetByTitle('Notas de Prensa')/items?$select=Id,Orden,Activo,Destacada,Title,Resumen, Created,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1&$top=3")
            .then(response => {
                let result: PressNote[] = [];

                response.forEach((pressNote: any) => {
                    result.push({
                        Id: pressNote.Id,
                        Titulo: pressNote.Title ? pressNote.Title : pressNote.File.Name,
                        Resumen: pressNote.Resumen,
                        Imagen: pressNote.Attachments ? pressNote.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        FechaPublicacion: new Date(pressNote.Created)
                    });
                });

                return result;
            });

        dispatch({ type: 'notasPrensa', value: pressNotes });
    }

    async function fetchOffices() {
        const oficinas = await Get("/Contactanos/_api/web/lists/GetByTitle('Oficinas')/items?$select=Orden,Activo,Title,Direccion,Telefono,Horario,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: HomeOficina[] = [];

                response.forEach((oficina: any) => {
                    result.push({
                        Titulo: oficina.Title ? oficina.Title : oficina.File.Name,
                        Direccion: oficina.Direccion,
                        Horario: oficina.Horario,
                        Telefono: oficina.Telefono,
                        ImagenDesktop: oficina.Attachments ? oficina.AttachmentFiles.results.find((a: any) => a.FileName.indexOf("mobile") < 0).ServerRelativeUrl : "",
                        ImagenMobile: oficina.Attachments ? oficina.AttachmentFiles.results.find((a: any) => a.FileName.indexOf("mobile") >= 0).ServerRelativeUrl : "",
                    });
                });

                return result;
            });

        dispatch({ type: 'oficinas', value: oficinas });
    }

    async function fetchCifrasIntro() {
        const data = await Get(`/Nosotros/_api/web/lists/GetByTitle('Cifras - Intro')/items?$select=Activo,Title,Subtitulo&$filter=Activo eq 1`)
            .then(response => {
                let result: GraphTitles[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Subtitulo: item.Subtitulo
                    });
                });

                return result;
            });

        if (data && data.length > 0)
            dispatch({ type: 'titulosGrafica', value: data[0] });
    }

    async function fetchGraph() {
        const grafica = await Get("/Nosotros/_api/web/lists/GetByTitle('Grafica Rentabilidad')/items?$select=Orden,Activo,Title, Cantidad&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: GraficaRentabilidad = {
                    Entradas: []
                }

                response.forEach((entrada: any) => {
                    result.Entradas.push({
                        Titulo: entrada.Title ? entrada.Title : entrada.File.Name,
                        Cantidad: entrada.Cantidad
                    });
                });

                return result;
            });

        dispatch({ type: 'graficaRentabilidad', value: grafica });
    }

    return (
        <HomeContext.Provider value={context}>
            {children}
        </HomeContext.Provider>
    );

};

const useHome = () => {
    const context = useContext(HomeContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useHome, HomeContextProvider };