import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { PageIntro } from "../../Models/page.models";
import { EstadoFinanciero, FinancialStatementsPage } from "./FinancialStatements.models";

type FinancialStatementsPageProps = { children: ReactNode; };
const FinancialStatementsPageContext = createContext<FinancialStatementsPage | undefined>(undefined);

type Message = { type: 'estados'; value: Array<EstadoFinanciero>; }
    | { type: 'intro'; value: PageIntro; };

const reducer = (state: FinancialStatementsPage, message: Message) => {
    switch (message.type) {
        case 'estados':
            return {
                ...state, Estados: message.value
            }

        case 'intro':
            return {
                ...state, Intro: message.value
            }
        default:
            return { ...state };
    }
};

const FinancialStatementsPageContextProvider = ({ children }: FinancialStatementsPageProps) => {

    const [financialStatementsPage, dispatch] = useReducer(reducer, {} as FinancialStatementsPage);

    useEffect(() => {
        fetchIntro();
        fetchStatements();
    }, []);

    async function fetchIntro() {
        const intro = await Get(`/Nosotros/_api/web/lists/GetByTitle('Estados Financieros - Intro')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1`)
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Resumen: item.Contenido
                    });
                });

                return result;
            });

        if (intro.length > 0)
            dispatch({ type: 'intro', value: intro[0] });
    }

    async function fetchStatements() {
        const data = await Get(`/Nosotros/_api/web/lists/GetByTitle('Estados Financieros')/items?$select=Orden,Activo,Title,Categoria,TipoDocumento,Ano,Modified,File/Length,File/Name,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1`)
            .then(response => {
                let result: EstadoFinanciero[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Categoria: item.Categoria,
                        TipoArchivo: item.TipoDocumento,
                        Ano: item.Ano,
                        Size: item.File.Length,
                        Url: item.File.ServerRelativeUrl,
                        Modified: new Date(item.Modified)
                    });
                });

                return result;
            });

        dispatch({ type: 'estados', value: data });
    }

    return (
        <FinancialStatementsPageContext.Provider value={financialStatementsPage}>
            {children}
        </FinancialStatementsPageContext.Provider>
    );

};

const useFinancialStatementsPage = () => {
    const context = useContext(FinancialStatementsPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useFinancialStatementsPage, FinancialStatementsPageContextProvider };