import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";
import { BannerTop } from "../../../components/BannerTop/BannerTop";
import { useMemoriesPage } from "../../../context/Publications/Memories/Memories.context";

import './Memorias.scoped.css';
import { DocumentsContainer } from "../../../containers/DocumentsContainer/documentsContainer";

export const Memorias = () => {
    const context = useMemoriesPage();

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Publicaciones",
            url: "/Publicaciones"
        },
        {
            title: "Memorias",
            url: "/Nosotros/Memorias"
        }
    ];

    return (
        <article id="memorias">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="intro">
                    <p>
                        {context?.Intro?.Resumen}
                    </p>
                </div>

                <DocumentsContainer filter={true} documents={context.Memorias}></DocumentsContainer>
            </div>
        </article>
    );
};