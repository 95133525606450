import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { GraficaRentabilidad } from "../../Home/home.models";
import { Cifra, GraphTitles, NumbersPage } from "./Numbers.models";

type NumbersPageProps = { children: ReactNode; };
const NumbersPageContext = createContext<NumbersPage | undefined>(undefined);

type Message = { type: 'titulosGrafica'; value: GraphTitles; }
    | { type: 'cifras'; value: Array<Cifra>; }
    | { type: 'graficaRentabilidad'; value: GraficaRentabilidad; };

const reducer = (state: NumbersPage, message: Message) => {
    switch (message.type) {
        case 'titulosGrafica':
            return {
                ...state, TitulosGrafica: message.value
            }
        case 'cifras':
            return {
                ...state, Cifras: message.value
            }
        case 'graficaRentabilidad':
            return {
                ...state, GraficaRentabilidad: message.value
            }
        default:
            return { ...state };
    }
};

const NumbersPageContextProvider = ({ children }: NumbersPageProps) => {

    const [numbersPage, dispatch] = useReducer(reducer, {} as NumbersPage);

    useEffect(() => {
        fetchCifrasIntro();
        fetchCifras();
        fetchGraph();
    }, []);

    async function fetchCifrasIntro() {
        const data = await Get(`/Nosotros/_api/web/lists/GetByTitle('Cifras - Intro')/items?$select=Activo,Title,Subtitulo&$filter=Activo eq 1`)
            .then(response => {
                let result: GraphTitles[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Subtitulo: item.Subtitulo
                    });
                });

                return result;
            });

        if (data && data.length > 0)
            dispatch({ type: 'titulosGrafica', value: data[0] });
    }

    async function fetchCifras() {
        const data = await Get(`/Nosotros/_api/web/lists/GetByTitle('Cifras')/items?$select=Orden,Activo,Title,Categoria,Prefijo,Monto,Sufijo&$orderby=Orden&$filter=Activo eq 1`)
            .then(response => {
                let result: Cifra[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Categoria: item.Categoria,
                        Prefijo: item.Prefijo || "",
                        Cantidad: item.Monto,
                        Decimales: item.Monto && item.Monto.toString().indexOf(".") >= 0 ? item.Monto.toString().substring(item.Monto.toString().indexOf(".") + 1).length : 0,
                        Sufijo: item.Sufijo || ""
                    });
                });

                return result;
            });

        dispatch({ type: 'cifras', value: data });
    }

    async function fetchGraph() {
        const grafica = await Get("/Nosotros/_api/web/lists/GetByTitle('Grafica Rentabilidad')/items?$select=Orden,Activo,Title, Cantidad&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: GraficaRentabilidad = {
                    Entradas: []
                }

                response.forEach((entrada: any) => {
                    result.Entradas.push({
                        Titulo: entrada.Title ? entrada.Title : entrada.File.Name,
                        Cantidad: entrada.Cantidad
                    });
                });

                return result;
            });

        dispatch({ type: 'graficaRentabilidad', value: grafica });
    }

    return (
        <NumbersPageContext.Provider value={numbersPage}>
            {children}
        </NumbersPageContext.Provider>
    );

};

const useNumbersPage = () => {
    const context = useContext(NumbersPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useNumbersPage, NumbersPageContextProvider };