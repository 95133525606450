import { Helmet } from "react-helmet";

export interface PageHeadersProps {
    title?: string;
}

export function PageHeaders(props: PageHeadersProps) {

    return (
        <Helmet>
            <title>{(props.title ? props.title + " | " : "") + "AFP Reservas"}</title>
            <link rel="icon" href="/ReactApp/favicon.ico"></link>

            <meta property="og:url" content="https://www.afpreservas.com/" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={(props.title ? props.title + " | " : "") + "AFP Reservas"} />
            <meta property="og:image" content="https://www.afpreservas.com/ReactApp/images/logo.png" />
            <meta property="og:image:alt" content="Logo de la AFP Reservas" />

            <meta property="twitter:domain" content="afpreservas.com" />
            <meta property="twitter:url" content="https://www.afpreservas.com/" />
            <meta name="twitter:title" content={(props.title ? props.title + " | " : "") + "AFP Reservas"} />
            <meta name="twitter:image" content="https://www.afpreservas.com/ReactApp/images/logo.png" />
        </Helmet>
    );
}