import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AboutUsPageContextProvider } from "../../context/AboutUs/AboutUs/AboutUs.context";
import { CorporateDocumentsPageContextProvider } from "../../context/AboutUs/CorporateDocuments/CorporateDocuments.context";
import { FamilyPageContextProvider } from "../../context/AboutUs/Family/Family.context";
import { FinancialStatementsPageContextProvider } from "../../context/AboutUs/FinancialStatements/FinancialStatements.context";
import { JoinUsPageContextProvider } from "../../context/AboutUs/JoinUs/JoinUs.context";
import { NormsPageContextProvider } from "../../context/AboutUs/Norms/Norms.context";
import { NumbersPageContextProvider } from "../../context/AboutUs/Numbers/Numbers.context";
import { Cifras } from "./Cifras/Cifras";
import { DocumentosCorporativos } from "./DocumentosCorporativos/DocumentosCorporativos";
import { EstadosFinancieros } from "./EstadosFinancieros/EstadosFinancieros";
import { Familia } from "./Familia/Familia";
import { FormaParte } from "./FormaParte/FormaParte";
import { NormasReglamento } from "./NormasReglamentos/NormasReglamentos";
import { QuienesSomos } from "./QuienesSomos/QuienesSomos";

export const Nosotros = () => {

    return (
        <Routes>
            <Route path="" element={<Navigate replace to="QuienesSomos" />} />
            <Route path="QuienesSomos" element={
                <React.Suspense fallback={<>...</>}>
                    <AboutUsPageContextProvider>
                        < QuienesSomos />
                    </AboutUsPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="FormaParte" element={
                <React.Suspense fallback={<>...</>}>
                    <JoinUsPageContextProvider>
                        < FormaParte />
                    </JoinUsPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Familia" element={
                <React.Suspense fallback={<>...</>}>
                    <FamilyPageContextProvider>
                        < Familia />
                    </FamilyPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Cifras" element={
                <React.Suspense fallback={<>...</>}>
                    <NumbersPageContextProvider>
                        < Cifras />
                    </NumbersPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="EstadosFinancieros" element={
                <React.Suspense fallback={<>...</>}>
                    <FinancialStatementsPageContextProvider>
                        < EstadosFinancieros />
                    </FinancialStatementsPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="DocumentosCorporativos" element={
                <React.Suspense fallback={<>...</>}>
                    <CorporateDocumentsPageContextProvider>
                        < DocumentosCorporativos />
                    </CorporateDocumentsPageContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Normas" element={
                <React.Suspense fallback={<>...</>}>
                    <NormsPageContextProvider>
                        < NormasReglamento />
                    </NormsPageContextProvider>
                </React.Suspense>
            }>
            </Route>
        </Routes>
    );
};