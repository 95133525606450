import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { Documento } from "../../Models/Document.models";
import { CorporateDocumentsPage, RelatedInstitution } from "./CorporateDocuments.models";

type CorporateDocumentsPageProps = { children: ReactNode; };
const CorporateDocumentsPageContext = createContext<CorporateDocumentsPage | undefined>(undefined);

type Message =
    { type: 'documentos'; value: Array<Documento>; }
    | { type: 'instituciones'; value: Array<RelatedInstitution>; };

const reducer = (state: CorporateDocumentsPage, message: Message) => {
    switch (message.type) {
        case 'documentos':
            return {
                ...state, Documents: message.value
            }
        case 'instituciones':
            return {
                ...state, Institutions: message.value
            }
        default:
            return { ...state };
    }
};

const CorporateDocumentsPageContextProvider = ({ children }: CorporateDocumentsPageProps) => {

    const [corporateDocumentsPage, dispatch] = useReducer(reducer, {} as CorporateDocumentsPage);

    useEffect(() => {
        fetchDocuments();
        fetchInstituciones();
    }, []);

    async function fetchDocuments() {
        const data = await Get(`/Nosotros/_api/web/lists/GetByTitle('Documentos Institucionales')/items?$select=Orden,Activo,Title,TipoDocumento,Modified,File/Length,File/Name,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1`)
            .then(response => {
                let result: Documento[] = [];


                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Tipo: item.TipoDocumento,
                        Ano: item.Ano,
                        Size: item.File.Length,
                        Url: item.File.ServerRelativeUrl,
                        Modified: new Date(item.Modified)
                    });
                });

                return result;
            });

        dispatch({ type: 'documentos', value: data });
    }

    async function fetchInstituciones() {
        const instituciones = await Get(`/Nosotros/_api/web/lists/GetByTitle('Instituciones Relacionadas')/items?$select=Orden,Activo,Title,Enlace,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1`)
            .then(response => {
                let result: RelatedInstitution[] = [];


                response.forEach((institution: any) => {
                    result.push({
                        Title: institution.Title,
                        Url: institution.Enlace,
                        Image: institution.AttachmentFiles && institution.AttachmentFiles.results.length > 0 ? institution.AttachmentFiles.results[0].ServerRelativeUrl : ""
                    });
                });

                return result;
            });

        dispatch({ type: 'instituciones', value: instituciones });
    }

    return (
        <CorporateDocumentsPageContext.Provider value={corporateDocumentsPage}>
            {children}
        </CorporateDocumentsPageContext.Provider>
    );

};

const useCorporateDocumentsPage = () => {
    const context = useContext(CorporateDocumentsPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useCorporateDocumentsPage, CorporateDocumentsPageContextProvider };