import './NotaPrensa.css';
import { usePressNote } from '../../../context/Publications/PressNote/PressNote.context';
import { PressNoteCard } from '../../../components/PressNotes/PressNoteCard/pressNoteCard';
import { PressNote } from '../../../context/Publications/publications.models';
import { useEffect, useState } from 'react';
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import { BannerTop } from '../../../components/BannerTop/BannerTop';

export const NotaPrensa = () => {
    const context = usePressNote();

    const [relatedNews, setRelatedNews] = useState<Array<PressNote>>([]);

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Publicaciones",
            url: "/Publicaciones"
        },
        {
            title: "Notas de Prensa",
            url: "/Publicaciones/NotasPrensa"
        }
    ];


    useEffect(() => {
        if (context.RelatedNews && context.RelatedNews.length > 0 && context.PressNote && context.PressNote.Id)
            setRelatedNews(context.RelatedNews.filter((pressNote: PressNote) => { return pressNote.Id != context.PressNote.Id }).slice(0, 2));
    }, [context.RelatedNews, context.PressNote]);

    return (
        <article id="press-note">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} slim={true} />
            </BannerTopContextProvider>

            <div className="note-container">
                <div className="container">
                    <img src={context.PressNote?.Imagen} alt={"Imagen de " + context.PressNote?.Titulo} className="desktop-item" />

                    <h3>{context.PressNote?.Titulo}</h3>

                    <div className="details">
                        <span className="icon-calendar"></span> Publicado el <strong>{context.PressNote?.FechaPublicacion?.getDate()}/{(context.PressNote?.FechaPublicacion?.getMonth() || 0) + 1}/{context.PressNote?.FechaPublicacion?.getFullYear()}</strong>
                    </div>

                    <img src={context.PressNote?.Imagen} alt={"Imagen de " + context.PressNote?.Titulo} className="mobile-item" />

                    <div className="note-content" dangerouslySetInnerHTML={{ __html: context.PressNote?.Contenido || "" }}></div>
                </div>
            </div>

            <div className="related-news">
                <div className="container">
                    <h3>Otros Artículos de Interés</h3>

                    <div className="related-news-container">
                        {
                            relatedNews?.map((pressNote: PressNote) => {
                                return (
                                    <PressNoteCard
                                        id={pressNote.Id}
                                        titulo={pressNote.Titulo}
                                        resumen={pressNote.Resumen}
                                        imagen={pressNote.Imagen}
                                        fechaPublicacion={pressNote.FechaPublicacion}
                                        key={pressNote.Id} />
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </article>
    );
};