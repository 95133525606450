import NumberFormat from "react-number-format";
import { Documento } from "../../../context/Models/Document.models";
import { StaticImage } from "../../Images/StaticImage/staticImage";

export interface DocumentCardProps {
    className?: string;
    document?: Documento;
    icon?: "download" | "view";
}

export function DocumentCard(props: DocumentCardProps) {
    return (
        <>
            <div className="documento desktop">
                <div className="type">
                    <StaticImage src={`/images/file-types/${props?.document?.Tipo}.svg`} alt="Ícono de tipo de archivo" />
                </div>
                <div className="info">
                    <h4 className="title">{props.document?.Titulo}</h4>
                    <div className="details">
                        <span>Modificado: <span className="data">{props?.document?.Modified?.getDate()}/{(props?.document?.Modified?.getMonth() || 0) + 1}/{props?.document?.Modified?.getFullYear()}</span></span>
                        <span>Tamaño:
                            <span className="data">
                                <NumberFormat
                                    value={props.document?.Size ? props.document?.Size / 1000 : 0}
                                    suffix=" KB"
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    thousandsGroupStyle="thousand"
                                    displayType="text" />
                            </span>
                        </span>
                    </div>
                </div>
                <div className="link-container">
                    <a className="primary-button" href={props.document?.Url} target="_blank">
                        Descargar
                        <span className={"icon-" + (props.icon ? props.icon : "download")}></span></a>
                </div>
            </div>

            <a href={props.document?.Url} className="documento mobile">
                <div className="type">
                    <StaticImage src={`/images/file-types/${props?.document?.Tipo}.svg`} alt="Ícono de tipo de archivo" />
                </div>
                <div className="info">
                    <h4 className="title">{props.document?.Titulo}</h4>
                    <div className="details">
                        <span>Modificado: <span className="data">{props?.document?.Modified?.getDate()}/{(props?.document?.Modified?.getMonth() || 0) + 1}/{props?.document?.Modified?.getFullYear()}</span></span>
                        <span>Tamaño:
                            <span className="data">
                                <NumberFormat
                                    value={props.document?.Size ? props.document?.Size / 1000 : 0}
                                    suffix=" KB"
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    thousandsGroupStyle="thousand"
                                    displayType="text" />
                            </span>
                        </span>
                    </div>
                </div>
            </a>
        </>
    );
}
