import DOMPurify from 'dompurify';
import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from '../../../util/sharepointRequests';
import { PageIntro } from "../../Models/page.models";
import { ContributionsCalculatorPage } from "./Contributions.models";

type ContributionsCalculatorPageProps = { children: ReactNode; };
const ContributionsCalculatorPageContext = createContext<ContributionsCalculatorPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: PageIntro; };

const reducer = (state: ContributionsCalculatorPage, message: Message) => {
    if (message.type == 'intro')
        return {
            ...state, Intro: message.value
        }
    else
        return { ...state };
};

const ContributionsCalculatorPageContextProvider = ({ children }: ContributionsCalculatorPageProps) => {

    const [benefits, dispatch] = useReducer(reducer, {} as ContributionsCalculatorPage);

    useEffect(() => {
        fetchIntro();
    }, []);

    async function fetchIntro() {
        const intro = await Get("/Afiliados/_api/web/lists/GetByTitle('Calculadora Aportes - Intro')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1")
            .then(response => {
                let result: PageIntro[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title,
                        Resumen: DOMPurify.sanitize(item.Contenido, { USE_PROFILES: { html: true } })
                    });
                });

                return result[0];
            });

        if (intro)
            dispatch({ type: 'intro', value: intro });
    }

    return (
        <ContributionsCalculatorPageContext.Provider value={benefits}>
            {children}
        </ContributionsCalculatorPageContext.Provider>
    );

};

const useContributionsCalculatorPage = () => {
    const context = useContext(ContributionsCalculatorPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useContributionsCalculatorPage, ContributionsCalculatorPageContextProvider };