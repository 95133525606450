import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { Get } from "../../../util/sharepointRequests";
import { Service, ServicesPage } from "./Services.models";

type ServicesPageProps = { children: ReactNode; };
const ServicesContext = createContext<ServicesPage | undefined>(undefined);

type Message = { type: 'services'; value: Service[]; };

const reducer = (state: ServicesPage, message: Message) => {
    if (message.type == 'services')
        return {
            ...state, Services: message.value
        }
    else
        return { ...state };
};

const ServicesContextProvider = ({ children }: ServicesPageProps) => {

    const [benefits, setBenefits] = useReducer(reducer, {} as ServicesPage);

    useEffect(() => {
        fetchServices();
    }, []);

    async function fetchServices() {
        const services = await Get("/Afiliados/_api/web/lists/GetByTitle('Servicios')/items?$select=Orden,Activo,Title,Contenido,Enlace,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1")
            .then(response => {
                let result: Service[] = [];

                response.forEach((benefit: any) => {
                    result.push({
                        icon: benefit.Attachments ? benefit.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        title: benefit.Title,
                        content: benefit.Contenido,
                        enlace: benefit.Enlace
                    });
                });

                return result;
            });

        setBenefits({ type: 'services', value: services });
    }

    return (
        <ServicesContext.Provider value={benefits}>
            {children}
        </ServicesContext.Provider>
    );

};

const useServices = () => {
    const context = useContext(ServicesContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useServices, ServicesContextProvider };