import { BannerTop } from "../../../components/BannerTop/BannerTop";
import { BriefCard } from "../../../components/Cards/BriefCard/briefCard";
import { Benefit } from "../../../context/Affiliates/Benefits/Benefit/Benefit.models";
import { useBenefits } from "../../../context/Affiliates/Benefits/Benefits.context";
import { BannerTopContextProvider } from "../../../context/Global/BannerTop/bannerTop.context";

import "./Beneficios.scoped.css";

export const Beneficios = () => {
    const context = useBenefits();
    const pageBreadcrumbs = [{
        title: "AFP Reservas",
        url: "/"
    },
    {
        title: "Afiliados",
        url: "/Afiliados"
    },
    {
        title: "Beneficios",
        url: "/Afiliados/Beneficios"
    }];

    return (
        <article id="beneficios">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="intro">
                    <p>{context?.Intro?.Resumen}</p>
                </div>

                <ul className="beneficios">
                    {
                        context?.Benefits?.map((benefit: Benefit) => {
                            return (
                                <li key={benefit.Id}>
                                    <BriefCard title={benefit.Titulo}
                                        image={benefit.Imagen || ""}
                                        summary={benefit.Resumen || ""}
                                        url={"/Afiliados/Beneficio?bId=" + benefit.Id}
                                        urlText={benefit.UrlText || ""}
                                    ></BriefCard>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </article>
    );
};