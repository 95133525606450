import './Actividades.css';
import { ActivitiesContainer } from '../../../containers/ActivitiesContainer/activitiesContainer';
import { useActivities } from '../../../context/Publications/Activities/Activities.context';
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import { BannerTop } from '../../../components/BannerTop/BannerTop';

export const Actividades = () => {
    const context = useActivities();

    const pageBreadcrumbs = [
        {
            title: "AFP Reservas",
            url: "/"
        },
        {
            title: "Publicaciones",
            url: "/Publicaciones"
        },
        {
            title: "Actividades",
            url: "/Nosotros/Actividades"
        }
    ];

    return (
        <section id="activities">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="intro" dangerouslySetInnerHTML={{ __html: context?.Intro?.Resumen || "" }}></div>

                <ActivitiesContainer filter={true} activities={context?.Activities} />
            </div>
        </section>
    );
};