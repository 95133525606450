import './App.css';
import { Footer } from './components/Footer/footer';
import { Header } from './components/Header/header';
import { FooterPageContextProvider } from './context/Global/Footer/footer.context';
import { HeaderPageContextProvider } from './context/Global/Header/header.context';
import { Router } from './routes/Routes';

function App() {
    return (
        <div>
            <HeaderPageContextProvider>
                <Header></Header>
            </HeaderPageContextProvider>
            <Router />
            <FooterPageContextProvider>
                <Footer></Footer>
            </FooterPageContextProvider>
        </div>
    );
}

export default App;
