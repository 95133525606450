import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { EntradaGrafica } from "../../../context/AboutUs/cifras.models";
import "./effectivenessGraph.scoped.css";

export interface EffectivenessGraphProps {
    classNameName?: string;
    title?: string;
    subtitle?: string;
    entradas?: Array<EntradaGrafica>;
    showLink?: boolean;
}

interface ApexChartSeries {
    name: string;
    data: Array<number>
}

export function EffectivenessGraph(props: EffectivenessGraphProps) {
    const [chartSeries, setChartSeries] = useState<Array<ApexChartSeries>>([]);
    const [chartOptions, setChartOptions] = useState<ApexOptions>({
        chart: {
            height: 350,
            type: "bar",
            toolbar: {
                show: false,
            },
            fontFamily: "FreightSans Pro"
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                dataLabels: {
                    position: "top", // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: string) {
                return val + "%";
            },
            offsetY: -30,
            style: {
                fontSize: "12px",
                colors: ["#FFF"],
            },
        },
        grid: {
            borderColor: "rgba(255, 255, 255, 0.1)",
        },
        fill: {
            colors: ['#264E72'],
            opacity: 1
        },
        xaxis: {
            categories: [],
            position: "bottom",
            tooltip: {
                enabled: false,
            },
            labels: {
                style: {
                    colors: "#FFF",
                },
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                style: {
                    colors: "#FFF",
                    fontFamily: "FreightSans Pro"
                },
            },
            tooltip: {
                enabled: false,
            },
            forceNiceScale: true,
            max: function (max: number) {
                return max + max * 0.3;
            },
        },
        tooltip: {}
    });

    useEffect(() => {
        if (props.entradas && props.entradas.length > 0) {
            setChartOptions({
                ...chartOptions,
                xaxis: {
                    categories: props.entradas.map(
                        entrada => { return entrada.Titulo; })
                }
            });
            setChartSeries([{
                name: "Rentabilidad",
                data: props.entradas.map(entrada => { return entrada.Cantidad; })
            }]);
        }
    }, props.entradas);

    return (
        <section id="cost-effectiveness-graph" >
            <div className="cost-effectiveness-graph-container">
                <div className="container">
                    <div className="graph-info">
                        <h2>{props.title}</h2>
                        <p>{props.subtitle}</p>

                        {
                            props.showLink &&
                            <Link to="/Nosotros/Cifras" className="desktop-item primary-button">Ver Indicadores</Link>
                        }
                    </div>
                    <div className="graph-container">
                        <Chart
                            options={chartOptions}
                            series={chartSeries}
                            type={chartOptions?.chart?.type}
                        />
                    </div>
                    {
                        props.showLink &&
                        <div className="mobile-link mobile-item">
                            <Link to="/Nosotros/Cifras" className="primary-button">Ver Indicadores</Link>
                        </div>
                    }
                </div>
            </div>
        </section >
    );
}
