import './questionCard.css';

export interface CollapsibleCardProps {
    index?: number;
    className?: string;
    title: string;
    content: string;
}

export function QuestionCard(props: CollapsibleCardProps) {
    return (
        <div className={"pregunta" + (props.className ? " " + props.className : "")}>
            <input type="checkbox" id={"pregunta-" + props.index} />
            <label htmlFor={"pregunta-" + props.index}>
                <span>{props.title}</span>
                <span className="plus-minus"></span>
            </label>
            <div className="respuesta" dangerouslySetInnerHTML={{ __html: props.content }}></div>
        </div>
    );
}
