import DOMPurify from 'dompurify';
import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Get } from '../../../../util/sharepointRequests';
import { Documento } from "../../../Models/Document.models";
import { Benefit, BenefitPage } from "./Benefit.models";

type BenefitPageProps = { children: ReactNode; };
const BenefitContext = createContext<BenefitPage | undefined>(undefined);

type Message =
    { type: 'benefit'; value: Benefit; }
    | { type: 'documents'; value: Array<Documento>; };

const reducer = (state: BenefitPage, message: Message) => {
    switch (message.type) {
        case 'benefit':
            return {
                ...state, Beneficio: message.value
            }
        case 'documents':
            return {
                ...state, Documentos: message.value
            }
        default:
            return { ...state };
    }
};

const BenefitContextProvider = ({ children }: BenefitPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as BenefitPage);
    const location = useLocation();
    const beneficioId = new URLSearchParams(location.search).get("bId") || "1";

    useEffect(() => {
        fetchBenefit();
        fetchDocuments();
    }, [location]);

    async function fetchBenefit() {
        const benefit = await Get(`/Afiliados/Beneficios/_api/web/lists/GetByTitle('Beneficios')/items?$select=Orden,Activo,Id,Title,Contenido,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1 and ID eq ${beneficioId}`)
            .then(response => {
                let result: Benefit[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Titulo: item.Title,
                        Contenido: DOMPurify.sanitize(item.Contenido, { USE_PROFILES: { html: true } }),
                        Imagen: item.Attachments && item.AttachmentFiles.results && item.AttachmentFiles.results.length > 0 ? item.AttachmentFiles.results[0].ServerRelativeUrl : ""
                    });
                });

                return result[0];
            });

        if (benefit)
            dispatch({ type: 'benefit', value: benefit });
    }

    async function fetchDocuments() {
        const documents = await Get(`/Afiliados/Beneficios/_api/web/lists/GetByTitle('Beneficios - Documentos')/items?$select=Orden,Activo,Title,Modified,TipoDocumento,BeneficioId,File/Length,File/Name,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1 and Beneficio/ID eq ${beneficioId}`)
            .then(response => {
                let result: Documento[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Titulo: item.Title ? item.Title : item.File.Name,
                        Tipo: item.TipoDocumento,
                        Size: item.File.Length,
                        Url: item.File.ServerRelativeUrl,
                        Modified: new Date(item.Modified)
                    });
                });

                return result;
            });

        dispatch({ type: 'documents', value: documents });
    }

    return (
        <BenefitContext.Provider value={state}>
            {children}
        </BenefitContext.Provider>
    );

};

const useBenefit = () => {
    const context = useContext(BenefitContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useBenefit, BenefitContextProvider };