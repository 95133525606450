import { useForm } from "react-hook-form";
import { BannerTop } from '../../../components/BannerTop/BannerTop';
import { useSignupRequest } from "../../../context/Employer/SignupRequest/SignupRequest.context";
import { BannerTopContextProvider } from '../../../context/Global/BannerTop/bannerTop.context';
import { Post } from "../../../util/sharepointRequests";
import './SolicitudRegistro.css';

export const SolicitudRegistro = () => {
    const context = useSignupRequest();
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (data: any) => {
        Post("/Empleador/_api/web/lists/GetByTitle('Solicitudes Registro')/items", data, "SP.Data.Solicitudes_x0020_RegistroListItem")
            .then(_response => {
                reset();
            });
    };

    const pageBreadcrumbs = [{
        title: "AFP Reservas",
        url: "/"
    },
    {
        title: "Empleador",
        url: "/Empleador"
    },
    {
        title: "Solicitudes",
        url: "/Empleador/Solicitudes"
    },
    {
        title: "Registrar Contacto de Empresa",
        url: "/Empleador/SolicitudRegistro"
    }];

    return (
        <section id="solicitud">
            <BannerTopContextProvider>
                <BannerTop breadcrumbs={pageBreadcrumbs} />
            </BannerTopContextProvider>

            <div className="container">
                <div className="info" dangerouslySetInnerHTML={{ __html: context.Intro || "" }}></div>

                <form className="solicitud-form">
                    <h3>Datos de la Empresa</h3>
                    <fieldset>
                        <div className="field">
                            <label htmlFor="identificacion-empresa">RNC o Cédula de Identidad*</label>
                            <input type="text"
                                id="identificacion-empresa"
                                placeholder="000-0000000-0"
                                {...register("Empresa_x0020_Identificacion", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="razon-empresa">Razón Social</label>
                            <input type="text"
                                id="razon-empresa"
                                placeholder="Razón Social"
                                {...register("Empresa_x0020_Razon", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="sector-empresa">Sector Empresarial</label>
                            <select id="sector-empresa"
                                {...register("Empresa_x0020_Sector", { required: true, })}>
                                <option value="null">Sector</option>
                                {
                                    context?.Sectores?.map((sector: string) => {
                                        return (
                                            <option key={sector} value={sector}>{sector}</option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                        <div className="field">
                            <label htmlFor="empleados-empresa">Cantidad de Empleados</label>
                            <input type="number"
                                id="empleados-empresa"
                                placeholder="000"
                                {...register("Empresa_x0020_Empleados", { required: true, })} />
                        </div>
                    </fieldset>

                    <h3>Datos de Contacto</h3>

                    <fieldset>
                        <div className="field">
                            <label htmlFor="nombre-contacto-empresa">Nombre del Contacto</label>
                            <input type="text"
                                id="nombre-contacto-empresa"
                                placeholder="Nombre del Contacto"
                                {...register("Contacto_x0020_Nombre", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="nacimiento-contacto-empresa">Fecha de nacimiento</label>
                            <input type="date"
                                id="nacimiento-contacto-empresa"
                                placeholder="00/00/0000"
                                {...register("Contacto_x0020_Nacimiento", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="cargo-contacto-empresa">Cargo</label>
                            <input type="text"
                                id="cargo-contacto-empresa"
                                placeholder="Cargo"
                                {...register("Contacto_x0020_Cargo", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="telefono-contacto-empresa">Número de Teléfono</label>
                            <input type="text"
                                id="telefono-contacto-empresa"
                                placeholder="(000)-000-0000"
                                {...register("Contacto_x0020_Telefono", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="extension-contacto-empresa">Extensión</label>
                            <input type="text"
                                id="extension-contacto-empresa"
                                placeholder="Extensión"
                                {...register("Contacto_x0020_Extension", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="direccion-contacto-empresa">Dirección</label>
                            <input type="text"
                                id="direccion-contacto-empresa"
                                placeholder="Dirección"
                                {...register("Contacto_x0020_Direccion", { required: true, })} />
                        </div>
                        <div className="field">
                            <label htmlFor="observaciones-empresa">Observaciones</label>
                            <textarea id="observaciones-empresa" cols={30} rows={1}
                                placeholder="Observaciones"
                                {...register("Contacto_x0020_Observaciones", { required: true, })}></textarea>
                        </div>
                    </fieldset>

                    <div className="submit-container">
                        <button type="button" className="primary-button"
                            onClick={handleSubmit(onSubmit)}>Enviar</button>
                    </div>
                </form>
            </div >
        </section >
    );
};
