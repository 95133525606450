import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { GetTitles } from "../../../util/sharepointRequests";
import { NormsPage } from "./Norms.models";

type NormsPageProps = { children: ReactNode; };
const NormsPageContext = createContext<NormsPage | undefined>(undefined);

type Message =
    { type: 'norms'; value: Array<string>; };

const reducer = (state: NormsPage, message: Message) => {
    if (message.type === 'norms')
        return {
            ...state, Normas: message.value
        }
    else
        return { ...state };

};

const NormsPageContextProvider = ({ children }: NormsPageProps) => {

    const [normsPage, setNormsPage] = useReducer(reducer, {} as NormsPage);

    useEffect(() => {
        fetchNorms();
    }, []);

    async function fetchNorms() {
        const norms = await GetTitles(`/Nosotros/_api/web/lists/GetByTitle('Normas y Reglamentos')/items?$select=Orden,Activo,Contenido&$orderby=Orden&$filter=Activo eq 1`);

        setNormsPage({ type: 'norms', value: norms });
    }

    return (
        <NormsPageContext.Provider value={normsPage}>
            {children}
        </NormsPageContext.Provider>
    );

};

const useNormsPage = () => {
    const context = useContext(NormsPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useNormsPage, NormsPageContextProvider };