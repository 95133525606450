import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { createRef, useEffect, useState } from "react";
import "./imageSlider.css";

export interface ImageSliderProps {
    className?: string;
    slides?: ImageSlide[];
}

export interface ImageSlide {
    Title?: string;
    Content?: string;
    TextUrl?: string;
    Image?: string;
    ImagenDesktop?: string;
    ImagenMobile?: string;
    Url?: string;
}

export function ImageSlider(props: ImageSliderProps) {
    return (
        <Splide
            id="image-slider"
            className={(props.className ? props.className : "")}
            options={{
                type: "loop",
                cover: false,
                autoplay: true,
            }}
            renderControls={() => (
                <div className="splide__arrows">
                    <button type="button" className="splide__arrow splide__arrow--prev">
                        <span className="icon-container">
                            <span className="icon-arrow-left"></span>
                        </span>
                    </button>
                    <button type="button" className="splide__arrow splide__arrow--next">
                        <span className="icon-container">
                            <span className="icon-arrow-right"></span>
                        </span>
                    </button>
                </div>
            )}>
            {
                props.slides?.map((slide: ImageSlide) => (
                    <SplideSlide key={slide.Title} className="splide__slide">
                        <div className="container">
                            <div className="background-image">
                                <img src={slide.ImagenDesktop} alt={'Imágen de Banner de ' + slide.Title} className="desktop" />
                                <img src={slide.ImagenMobile} alt={'Imágen de Banner de ' + slide.Title} className="mobile" />
                            </div>
                            <div className="background"></div>
                            <div className="content">
                                <div className="slider-info">
                                    <h1>{slide.Title}</h1>
                                    <h2>{slide.Content}</h2>

                                    {
                                        (slide.Url && slide.TextUrl) &&
                                        <a href={slide.Url || ""} className="primary-button">{slide.TextUrl}</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                ))
            }
        </Splide>
    );
}
