import { createContext, ReactNode, useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Get, GetFieldOptions } from "../../../util/sharepointRequests";
import { RequestInfo } from "../Requests/Requests.models";
import { VisitRequestPage } from "./VisitRequest.models";

type VisitRequestPageProps = { children: ReactNode; };
const VisitRequestContext = createContext<VisitRequestPage | undefined>(undefined);

type Message =
    { type: 'intro'; value: string; }
    | { type: 'zonas'; value: Array<string>; }
    | { type: 'solicitudes'; value: Array<string>; };

const reducer = (state: VisitRequestPage, message: Message) => {
    switch (message.type) {
        case 'intro':
            return {
                ...state, Intro: message.value
            }
        case 'zonas':
            return {
                ...state, Zonas: message.value
            }
        case 'solicitudes':
            return {
                ...state, Solicitudes: message.value
            }
        default:
            return { ...state };
    }
};

const VisitRequestContextProvider = ({ children }: VisitRequestPageProps) => {

    const [state, dispatch] = useReducer(reducer, {} as VisitRequestPage);
    const location = useLocation();

    useEffect(() => {
        fetchIntro();
        fetchZonas();
        fetchSolicitudes();
    }, [location]);

    async function fetchIntro() {
        const data = await Get(`/Empleador/_api/web/lists/GetByTitle('Solicitudes')/items?$select=Id,Activo,Intro&$filter=Activo eq 1 and Enlace eq '${location.pathname}'`)
            .then(response => {
                let result: RequestInfo[] = [];

                response.forEach((item: any) => {
                    result.push({
                        Id: item.Id,
                        Intro: item.Intro
                    });
                });

                return result;
            });

        dispatch({ type: 'intro', value: data[0].Intro || "" });
    }

    async function fetchZonas() {
        const data = await GetFieldOptions("/Empleador/_api/web/lists/GetByTitle('Solicitudes Visita')", "Empresa_x0020_Zona")
            .then(response => {
                return response;
            });

        dispatch({ type: 'zonas', value: data });
    }

    async function fetchSolicitudes() {
        const data = await GetFieldOptions("/Empleador/_api/web/lists/GetByTitle('Solicitudes Visita')", "Tipo_x0020_Solicitud")
            .then(response => {
                return response;
            });

        dispatch({ type: 'solicitudes', value: data });
    }

    return (
        <VisitRequestContext.Provider value={state}>
            {children}
        </VisitRequestContext.Provider>
    );

};

const useVisitRequest = () => {
    const context = useContext(VisitRequestContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useVisitRequest, VisitRequestContextProvider };